import styled from 'styled-components'

export const FooterStyle = styled.footer`
 padding: 1rem 5rem;
	display: flex;
	align-items: center;
	color: #fff;
	background: #333;

	a:hover {
		font-weight: 700;
	} 

	.author-info {
		display: flex;
		justify-content: space-around;
		width: 100%;
		padding: .5rem 2rem;
		p, a {
			color: #fff;
			font-size: .7rem; 
			text-transform: uppercase;
			transition: all 300ms ease;
		}
	}

	.social {
		width: 30%;
		h4 {
			padding-bottom: 0rem;
		}
		.social-icons {
			padding-top: .5rem;
			display: grid;
			grid-gap: .5rem; 
			gap: .5rem;
			grid-template-columns: 5% 5% 5%;
			svg {
				color: #fff;
				transition: transform 200ms ease-in;
				&:hover {
					transform: scale(1.2);
				}
			}
		}
	}

 @media (max-width: 768px) {
 	padding: 1rem;
 	flex-direction: column;

 	.author-info {
 		width: 100%;
 	}

 	.social {
 		width: 100%;
 		text-align: center;
 		.social-icons {
 			justify-content: center;
 			padding-top: 1rem;
 			padding-bottom: 1rem;
 			grid-template-columns: 10% 10% 10%;
 		}
 	}
 }
 @media (max-width: 415px) {
 	padding: 1.5rem .5rem .5rem .5rem;

 	.social {
 		.social-icons {
 			padding-top: .5rem;
 		}
 	}
 }
`