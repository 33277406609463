import {useState, useEffect} from 'react'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import CurrentTestNav from './CurrentTestNav'
import MainTestButton, {NextTestButton} from './MainTestButton'
import { useNavigate, useLocation } from "react-router-dom"
import finishedTestAction from '../actions/finishedTestAction'
import {ThunkDispatch} from 'redux-thunk'
import TestPlayer from './TestPlayer'
import LoadingGif from './LoadingGif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay} from '@fortawesome/free-solid-svg-icons'
import {TestFinishButton} from './MainTestButton'
//@ts-ignore
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import {TestBox, MainTest, AnswersStyle, PrepareTestStyle} from '../wrappers/CurrentTest'
import {currentTestSate, answerTypeRecords} from '../types'
import {setAlert} from '../actions/infoAlertAction'

export type testType = {
	_id: string,
	audio: string,
	question: string,
	answers: {
		_id: string,
		answer: string
	}[],
}

//main component
function CurrentTest() {
	const [prepareState, setPrepareState] = useState(true)
	const {tests, testId, isLoading}: currentTestSate = useSelector((state: AppStateType) => state.currentTest)
	const {id: userId, isLogedIn} = useSelector((state: AppStateType) => state.user)
	//set all user's answers
	const [answerList, setAnswerList] = useState<answerTypeRecords>({})
	//answer on current test
	const [answer, setAnswer] = useState('')
	//set index of current test
	const [currentIndex, setCurrentIndex] = useState<number>(0)
	//set single current test
	const [singleTest, setSingleTest] = useState<testType>(tests[currentIndex])
	//is test answered already
	const [isAnswered, setAnswered] = useState<boolean>(() => answerList.hasOwnProperty(currentIndex))
	//for navigation
	const navigate = useNavigate()
	const {pathname} = useLocation()
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()

	const playIcon = <FontAwesomeIcon className="play" icon={faPlay} />

	useEffect(() => {
			setAnswered(() => answerList.hasOwnProperty(currentIndex))
	}, [currentIndex, answerList])


	useEffect(() => {
		if (Object.keys(answerList).length === (tests.length)) {
			nprogress.start()
			dispatch(finishedTestAction(testId, answerList))
			nprogress.done()
			if(pathname.startsWith('/user') && isLogedIn) {
				navigate('/user/my-page/finished-test')
			} else {
				navigate('/finished-test')
			}		
		}
	}, [answerList, navigate, testId, dispatch, tests.length])

	//change test on click
	function changeSingleTest(n: number) {
		if (n === tests.length) {
			for (let i = 0; i < tests.length; i++) {
				if (answerList.hasOwnProperty(i)) {
					continue
				} else {
					setSingleTest(tests[i])
					setCurrentIndex(i)
					setAnswer('')
					break			
				}
			}
		} else {
			setSingleTest(tests[n])
			setCurrentIndex(n)
			setAnswer('')		
		}
	}

	//set a current answer
	function checkBoxHandler(e: React.ChangeEvent<HTMLInputElement>) {
		setAnswer(e.target.value)
	}

	//add a new answer
	function newAnswerHandler() {
		setAnswerList({
			...answerList,
			[currentIndex]: {
				answerId: singleTest._id,
				answer: answer
			}
		})
		setAnswer('')
		changeSingleTest(currentIndex + 1)	
	}

	//finished test 
	function finishTestHandler() {
		setAnswerList({
			...answerList,
			[currentIndex]: {
				answerId: singleTest._id,
				answer: answer
			}
		})
		setAnswer('')
	}

 return (
 	isLoading ? <LoadingGif/>
 	: <TestBox>
    	<CurrentTestNav 
    	answerList={answerList} 
    	navigate={changeSingleTest} 
    	index={currentIndex} 
    	length={tests.length}/>
    	<MainTest>
    		{prepareState 
    		? <PrepareTestStyle onClick={() => setPrepareState(false)}>
							 	<h3>Click on button below to start:</h3>
							 	<div className="play-wrapper">
								 	<span>
								 		{playIcon}
								 	</span>	 							 		
							 	</div>
					 		</PrepareTestStyle>
					 : <>	
    		<h3>{singleTest?.question}</h3>
    		<TestPlayer currentAudio={singleTest?.audio}/>
    		<h4>{isAnswered 
 						? "Your answer:" 
 						: "Choose the correct answer:"}
 	   	</h4>
    		<AnswersStyle>
 					<div className="answer-box">   			
 						{singleTest?.answers.map((item: {_id: string, answer: string}) => {
    				 return (
    					<div key={item._id}>
 	   					<label
 									className={`${isAnswered !== false 
 										&& item?.answer === answerList[currentIndex]?.answer
 										? 'bold-test'
 										: ''}`} 
 									htmlFor={`check-${item._id}`}>
 		 							<input
 		 							className="custom-checkbox" 
 		 							checked={answer === item.answer
 		 								? true
 		 								: false}
 		 							name="answersInput" 
 		 							value={item.answer} 
 		 							type='radio' id={`check-${item._id}`} 
 		 							onChange={checkBoxHandler}/>
 
 										<p className="custom-checkbox-text">{item.answer}</p>
 									</label>
    					</div>
    					)
    			})}
 					</div>
    			{Object.keys(answerList).length === (tests.length - 1) && !answerList.hasOwnProperty(currentIndex)
    				//renders only when one last test left
    				? <TestFinishButton answer={answer} finishTestHandler={finishTestHandler}/>
    				//renders when 1+ test left 
    				: <>
    							<MainTestButton answer={answer} isAnswered={isAnswered} newAnswerHandler={newAnswerHandler}/>
    							<NextTestButton changeSingleTest={changeSingleTest} currentIndex={currentIndex} isAnswered={isAnswered}/>
    						</>
    			}
    		</AnswersStyle>
    	</>}
    	</MainTest>
    </TestBox>
 );
}

export default CurrentTest;