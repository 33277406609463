import {ThumbNail, ThumbNailItem} from '../wrappers/CurrentTestNavStyles'
import {answerTypeRecords, currentTestNavType} from '../types'


function CurrentTestNav({navigate, index, length, answerList}: currentTestNavType) {
	let divs = [];
	for (let i = 0; i <= length - 1; i++) {
		divs.push(<ThumbNailItem
			className={`${i === index ? 'activeNav' : ''} 
			${answerList.hasOwnProperty(i) ? 'answeredNav' : ''}`} 
			onClick={() => navigate(i)}
			key={i}>{i + 1}</ThumbNailItem>);
	}
	return (
			<ThumbNail>
			{divs}
			</ThumbNail>
		)
}

export default CurrentTestNav;