import {DonateStyles} from '../wrappers/DonatePageStyles'
import {useNavigate} from "react-router-dom"
import styled from 'styled-components'
import mockup from '../img/phone_macbook.png'

export const SubscribeStyles = styled.div`
	padding: 1rem 5rem 5rem 5rem;
	.main-block {
	text-align: center;
	h2.main-header {
		display: inline-block;
		&:after {
			opacity: .6;
			margin-top: .5rem;
			margin-left: auto;
			margin-right: auto;
			content: '';
			display: block;
			width: 4rem;
			height: 3px;
			background-color: #EF4F4F;
		}
	}
}

	.img-container {
		text-align: center;
		img {
			display: inline-block;
			width: 60%;
		}
	}

	@media (max-width: 768px) {
		padding: 0rem 2rem 3rem 2rem;
		.img-container {
			text-align: center;
			img {
				display: inline-block;
				width: 100%;
			}
		}
	}



	@media (max-width: 415px) {
		padding: 0rem 1rem 3rem 1rem;
		button {
			width: 90%;
		}
		.main-block {
			h3.main-header {
				&:after {
					width: 4rem;
				}
		}
	}
}

	@media (max-width: 330px) {
		padding: 0rem .5rem 3rem .5rem;
	}


`

function SubscribePage() {
	const navigate = useNavigate()

	return(
		<SubscribeStyles id="subscribe">
			<div className="main-block">
				<h2 className="main-header">Subscribe</h2>
				<p>Start Your Free <b>30-Day Trial Account</b> to Track and Enhance Your Personal Progress:</p>
				<div className="img-container">
					<img src={mockup} alt="mockup_mac_phone"/>
				</div>
				<div className="button-container">
					<button className="main-button" onClick={() => navigate('/register')}>Create an account</button>
				</div>
			</div>
		</SubscribeStyles>
		)
}

export default SubscribePage