import styledComp from 'styled-components'


export const MyPageStyles = styledComp.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;

	h3.main-header {
		&:after {
			opacity: .6;
			margin-top: .5rem;
			content: '';
			display: block;
			width: 4rem;
			height: 2px;
			background-color: #EF4F4F;
		}
	}

	.sidebar{
	 width: 25%;
	 background: #2C1049;
	 background: linear-gradient(to left #2C1049 50%, #3E2F65 50%);
	 transition: all .2s ease;  
	 background-color: #fff;
		box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
		z-index: 100;

		& .nav-item {
			background: rgba(183, 163, 236, .2);
		}

		a.active li {
			background: #b7a3ec90;
		}
	}

	.sidebar ul li div{
	  list-style: none;
	  text-decoration: none;
	  color: #FFFFFF;
	  display: flex;
	  column-gap: 14px;
	  align-items: center;
	  & span {
	  	color: #696969;
	  }

	  & i {
	  	width: 1.8rem;
	  }
	}

	.sidebar .sidebar_items{
	  padding-top: px;
	  padding-bottom: 10px;   
	}

	.sidebar .sidebar_items_list{
	  padding: 10px 18px;
	}

	.sidebar .sidebar_items_list:hover{
	 background: #b7a3ec90;
	 cursor: pointer;  
	}

	.sidebar i{
	 font-size: 24px;
	 color: #97A1BC
	}


	.sidebar span {
	  font-size: 1rem;
	}

	.sidebar .sidebar-toggle i{
	}

	::-webkit-scrollbar {
	    width: 8px;
	  }
	  
	  /* Track */
	  ::-webkit-scrollbar-track {
	    background: #EFEAFC;    
	  }
	   
	  /* Handle */
	  ::-webkit-scrollbar-thumb {
	    background: #613fb8; 
	  }
	  
	  /* Handle on hover */
	  ::-webkit-scrollbar-thumb:hover {
	    background: #3E2F65; 
	 }

	/* active */
	.sidebar.active{
	    width: 5%;
	}

	.sidebar.active .sidebar_items .sidebar_items_list span{
	    display: none;
	}

	.sidebar.active .sidebar-toggle span{
	    display: none;
	}

	.content-panel {
		background-color: #FAFAFA;
		min-height: 110vh;
		width: 100%;
		padding: 0rem 3rem;
	}

	@media (max-width: 900px) {
		.sidebar .sidebar_items .sidebar_items_list span{
		    display: none;
		}

		.sidebar .sidebar_items_list{
		  padding: 10px 16px;
		}

		.sidebar_nav_item, .sidebar_items_list {
			text-align: center;
		}

		.sidebar .sidebar-toggle span{
		    display: none;
		}		

		.sidebar, .sidebar.active {
		    width: 8%;
		  i {
		  	font-size: 20px;
		  }
		}
		.sidebar_items_list.nav-item {
			display: none;
		}

		.content-panel {
			padding: 0rem 2rem;
		}
	}

	@media (max-width: 768px) {
		.sidebar, .sidebar.active {
		    width: 8%;
		  i {
		  	font-size: 18px;
		  }
		}

		.sidebar .sidebar_items_list{
		  padding: 10px 12px;
		}

		.content-panel {
			padding: 0 1rem;
		}
	}

	@media (max-width: 515px) {
		h3.main-header {
			&:after {
				margin-left: auto;
				margin-right: auto;
			}
		}
		.sidebar, .sidebar.active {
		    width: 12%;
		  i {
		  	font-size: 16px;
		  }
		}
		.content-panel {
			padding: 0 .5rem;
		}
	}
`