import styled from 'styled-components'

export const TestStyle = styled.div`
	height: 100%;
	position: relative;
	padding: 2rem 2rem 4rem 2rem;
	border: 2px solid #f1f3f4;
	box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
	border-radius: .5rem;
	overflow: hidden;
	cursor: pointer;
	transition: all 200ms ease-in;
	&:hover {
		box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	}
	img {
		height: 40vh;
		width: 100%;
		object-fit: cover;
		object-position: left top;
	}
	h3 {
		padding: 0;
		opacity: .8;	
	} 
	button {
		position: absolute;
	 bottom: 1.5rem;
	 left: 0;
	 transform: translateX(2rem);
	}
`

export const Description = styled.div`
	padding: 1.5rem 0;
	img {
		display: inline-block;
		width: 1rem;
		height: 1rem;	
	}

	.test-description {
		padding-top: 1rem;
	}

.technique {
	opacity: .8;
	text-align: center;
	display: inline-block;
	padding: 0 .8rem;
	border-radius: 20px;
	color: white;
	font-size: .8rem;
	margin-bottom: 1rem;
}

.description-header {
	padding-right: .8rem;
}
`

export const Raiting = styled.p`
	display: flex;
	align-items: center;
	span {
		opacity: .7;
	}
	.empty-star {
		opacity: .4;
	}
`