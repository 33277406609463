import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRightLong, faMusic, faDrum, faGuitar} from '@fortawesome/free-solid-svg-icons'

const noteIcon = <FontAwesomeIcon icon={faMusic} />
const rhythmIcon = <FontAwesomeIcon icon={faDrum} />
const techIcon = <FontAwesomeIcon icon={faGuitar} />

export const techiqueMap = new Map([
		['Harmonic', noteIcon],
		['Rhythm', rhythmIcon],
		['Technique', techIcon],
		['Гармонический слух', noteIcon],
		['Ритмика', rhythmIcon],
		['Техника', techIcon],  
	])

export function getIcon(technique: string) {
	const icon = techiqueMap.get(technique)
	return icon
}