import {useDispatch, useSelector} from 'react-redux'
import {useRef, useState} from 'react'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {ThunkDispatch} from 'redux-thunk'
import SampleTestPlayer from './SampleTestPlayer'
import {useNavigate, useLocation} from "react-router-dom"
import {finishedTestReset} from "../actions/finishedTestAction"
import {FinishedTestStyles, AnswersBox, AnswerItem} from '../wrappers/FinishedTestStyles'
import LoadingGif from './LoadingGif'
import {SingleAnswerType} from '../types'
import {setAlert} from '../actions/infoAlertAction'
import {useEffect} from 'react'


function setFontColor(SuccessRate: number) {
	if(SuccessRate <= 30) return 'red'
	if(SuccessRate <= 70) return 'orange'
	if(SuccessRate <= 100) return 'green'
}


function FinishedTest() {
	const {answers, succeededTests, isLoading, testId} = useSelector((state: AppStateType) => state.finishedTest)
	const {id: userId, isLogedIn} = useSelector((state: AppStateType) => state.user)
	const SuccessRate = Math.floor(succeededTests / answers.length * 100)
	const [playingAudio, setPlayingAudio] = useState(answers[0].audio)
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
	const audioRef = useRef<any | HTMLAudioElement>(null)
	const navigate = useNavigate()
	const {pathname} = useLocation()

	useEffect(() => {
		dispatch(setAlert('Test completed!'))
	}, [])

	return(
		<FinishedTestStyles className="container">
			<h3 className="main-header">Test completed!</h3>
			{isLoading
				? <LoadingGif/>
				: <>
				<div className="main-info">
					<h4>Correct answers:</h4>
					<div className="main-info-success"
					style={{'color': setFontColor(SuccessRate)}}>{succeededTests}</div>
				</div>
				<AnswersBox>
					{answers.map((answer: SingleAnswerType) => {
						return<AnswerItem key={answers.indexOf(answer)}>
						<div className="item-body">
							<h4>{answer.question}</h4>
							{!answer.isCorrect 
								? <>
										<p className="incorrect">{answer.usersAnswer.toLowerCase()}</p>
										<p className="correct">Correct answer: {answer.correct.toLowerCase()}</p>								
										</>
								: <p className="correct">Correct answer: {answer.usersAnswer.toLowerCase()}</p>}
								<SampleTestPlayer
								setPlayingAudio={setPlayingAudio}
								audioRef={audioRef}
								currentAudio={answer.audio} 
								playingAudio={playingAudio}/>
							</div>
						</AnswerItem>
					})}
					
	  		<audio
	  		onEnded={() => setPlayingAudio('')}
	    ref={audioRef} 
	    src={`https://earmentor.onrender.com/${playingAudio}`}>    
	    </audio>
	    <div className="button-box">
					<button 
						onClick={() => {
							dispatch(finishedTestReset())
							pathname.startsWith('/user')
							? navigate(`/user/my-page/current-test/${testId}`)
							: navigate(`/current-test/${testId}`)
						}}
						className="main-button">Try again</button>
						<button
						onClick={() => {
							dispatch(finishedTestReset())
							pathname.startsWith('/user')
							? navigate(`/user/my-page/train`)
							: navigate('/')
						}}
						className="simple-button">Back to all tests</button>	    	
	    </div>
				</AnswersBox>
				</>
			}

		</FinishedTestStyles>
		)
}

export default FinishedTest;