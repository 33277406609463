import CurrentTest from './CurrentTest'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import LoadingGif from './LoadingGif'
import {currentTestAction} from '../actions/currentTestAction'
import {useParams, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {currentTestSate} from '../types'
import {resetCurrentTestAction} from '../actions/currentTestAction'

//main component
function CurrentTestWrapper() {
	const params = useParams()
	const location = useLocation()
	const [userPage, setUserPage] = useState(false)
	const testId = params.id
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
	const {isLoading}: currentTestSate = useSelector((state: AppStateType) => state.currentTest)

	useEffect(() =>  {
		window.scrollTo(0, 0)
		if (typeof testId !== 'undefined') {
			dispatch(currentTestAction(testId))
		} 
	}, [testId, dispatch])

	useEffect(() => {
		if (location.pathname.startsWith('/user')) {
			setUserPage(true)
		}
	}, [location])

	
	return(
		<div className={userPage ? '' : 'container'}>
		{isLoading 
			? <div className="loader-container"><LoadingGif/></div>
			: <CurrentTest/>}
		</div>
		)
}
export default CurrentTestWrapper