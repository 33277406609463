import {useState, useRef, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay, faPause, faCompactDisc} from '@fortawesome/free-solid-svg-icons';
//@ts-ignore
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import {PlayerStyle} from '../wrappers/TestPlayer'
import {PlayerType} from '../types'

export const loadingDisc = <FontAwesomeIcon size="2x" className="loading-icon" icon={faCompactDisc} />

//main component
function Player({currentAudio}: PlayerType) {
	const [isPlaying, setPlaying] = useState<boolean>(false);
	const [audioLoading, setLoading] = useState<boolean>(true);
	const [slowAnimation, setAnimation] = useState<boolean>(true);
 	const [audioInfo, setAudioInfo] = useState({
   	currentTime: 0,
   		duration: 0,
   animatedInput: 0
 	})
 	const [slowMode, setSlowMode] = useState(false)
	const audioRef = useRef<any | HTMLAudioElement>(null);
	//console.log(audioRef.current.playbackRate)
	//icons
	const playIcon = <FontAwesomeIcon onClick={playSongHandler} size="2x" className="play" icon={faPlay} />
	const pauseIcon = <FontAwesomeIcon onClick={playSongHandler} size="2x" className="pause" icon={faPause} />
	

	useEffect(() => {	
		setAnimation(false)
		setLoading(true)
		setPlaying(true)
		setAudioInfo({
	    currentTime: 0,
	    duration: 0,
	    animatedInput: 0			
		})
		startPlaying()
		setSlowMode(false)
	}, [currentAudio])

	useEffect(() => {
		if(slowMode) {
			audioRef.current.playbackRate = 0.5
		} else {
			audioRef.current.playbackRate = 1
		}
	}, [slowMode])

//handlers
	async function startPlaying() {
		nprogress.start()
		await audioRef.current.play()
		nprogress.done()
		setTimeout(() => setLoading(false), 250);
	}


	function playSongHandler(): void {
		if (isPlaying) {
			audioRef.current.pause();
			setPlaying(false);
		} else {
			audioRef.current.play();
			setPlaying(true);
			setTimeout(() => setAnimation(true), 100);
		}
	}

	function slowModeHandler() {
		if(slowMode) {
			setSlowMode(false)
		} else {
			setSlowMode(true)
		}
	}

	function timeUpdateHandler(e: React.ChangeEvent<HTMLAudioElement>): void {
		let current = e.target.currentTime;
		let duration = e.target.duration;
		setAudioInfo({
		  ...audioInfo,
		  currentTime: current,
		  animatedInput: Math.round(current / duration * 100),
		})
	}

	function dragHandler(e: React.ChangeEvent<HTMLInputElement>): void {
		audioRef.current.currentTime = e.target.value;
		setAudioInfo({
			...audioInfo,
			currentTime: +e.target.value
		});
	}

	function getDurationHandler(e: React.ChangeEvent<HTMLAudioElement>): void  {
		setAnimation(true)
		setAudioInfo({
		  ...audioInfo,
		  duration: e.target.duration,
		  animatedInput: 0
		})
	}

	function audioEndHandler() {
		setPlaying(false)
		setAnimation(false)
		setAudioInfo({
			...audioInfo,
			currentTime: 0,
			animatedInput: 100
		})
	}


	return(
		<PlayerStyle>
			<div className="player">
				<div className="play-control" id="play-control">
				{audioLoading 
					? loadingDisc
					: isPlaying ? pauseIcon : playIcon}
				</div>
				<div className="time-control">
					<div style={{background: `linear-gradient(to right, #6B7AA1, #97A1BC)`}} className="track">				
						<input 
							type="range" 
							min={0} 
							id="volume"
							name="volume"
							value={audioInfo.currentTime} 
							max={audioInfo.duration} 
							onChange={dragHandler}/>
							<div 
							style={{transform: `translateX(${audioInfo.animatedInput}%)`,
							transition: `${slowAnimation 
								? isPlaying ? "transform 250ms linear" : "transform 0ms linear"
								: "transform 0ms linear"}`}} className="animate-track"></div>
					</div>
				</div>
				<div className="play-control speed-control" onClick={() => slowModeHandler()}>
				<span>{audioLoading 
					? '100%'
					: slowMode ? '50%' : '100%'}</span>
				</div>
	  <audio
	  	onEnded={audioEndHandler} 
	    onLoadedMetadata={getDurationHandler} 
	    ref={audioRef} 
	    src={`https://earmentor.onrender.com/${currentAudio}`} 
	    onTimeUpdate={timeUpdateHandler}></audio>
			</div>
		</PlayerStyle>
		)
}


export default Player;