import styled from 'styled-components'
import bgimageGrad from '../img/grad-9.png'

export const FirstScreen = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	background-color: #FAFAFA;
	.main-block {
			width: 100%;
			height: calc(100vh - 4rem);
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			background-size: cover;
			background-position: center;

		p {
			color: #fff;
			text-transform: uppercase;
			&:after {
				content: '';
				display: block;
				width: 4rem;
				height: 2px;
				background-color: #EF4F4F;
				margin: 0 auto;
			}
		}

		h3 {
			color: #fff;
			font-size: 2.2rem;
		}

		button.main-button {
			padding: .6rem 4rem;
			margin-top: 1.5rem;
			svg {
				padding-left: 1.5rem;
				animation: bounce 10s infinite;
				animation-delay: 10s;
			}
		}
	}

	.image-block {
		display: none;
		img {
			max-width: 100%;
			height: calc(100vh - 4rem);
			object-fit: cover;
		}
	}

	@media (max-width: 768px) {
		.main-block h3 {
			font-size: 1.8rem;
		}
	}

	@media (max-width: 415px) {
		.image-block {
			display: none;
		}

		.main-block {
			width: 100%;
			height: calc(100vh - 4rem);
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			background-position: center;
			padding: 0 .5rem;
			h3, p {
				color: #fff;
				text-transform: uppercase;
			}
			h3 {
				font-size: .9rem;
				line-height: 1.2rem;
			}
			p {
				&:after {
					width: 4rem;
				}
			}
			button.main-button {
				margin-top: 1rem;
				width: 90%;
				svg {
					padding-left: .5rem;
				}
			}
		}
	}

	@keyframes bounce {
	  0%, 4%, 10%, 16%, 20%, 100% {
	    transform: translateY(0);
	  }
	  8% {
	    transform: translateY(-6px);
	  }
	  12% {
	    transform: translateY(-2px);
	  }
	}
`