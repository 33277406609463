import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInstagram, faTelegram} from '@fortawesome/free-brands-svg-icons'
import {faGlobe} from '@fortawesome/free-solid-svg-icons'
import {FooterStyle} from '../wrappers/FooterStyles'
import {HashLink} from 'react-router-hash-link'
import { scrollHook } from '../lib/scrollHook';

function Footer() {
	const instIcon = <FontAwesomeIcon icon={faInstagram}/>
	const tgIcon = <FontAwesomeIcon icon={faTelegram}/>
	const webIcon = <FontAwesomeIcon icon={faGlobe}/>

	return(
		<FooterStyle>
			<div className="author-info">
				<HashLink scroll={(el) => scrollHook(el)} to="/user/my-page">Account</HashLink>
				<HashLink scroll={(el) => scrollHook(el)} to="/#tests">Tests</HashLink>
				<HashLink scroll={(el) => scrollHook(el)} to="/#donate">Pricing</HashLink>
				<HashLink scroll={(el) => scrollHook(el)} to="/#about">About</HashLink>
			</div>
		</FooterStyle>
		)
}

export default Footer;