import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {AppStateType} from '../reducers/index'
import customFetch from '../axios'
import axios from 'axios'
import {throwErrorAction} from './errorAction'

export const searchProgressDataAction = () => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	const url = `https://earmentor.onrender.com/api/v1/tests/progress`

	try {
		dispatch({type: 'LOADING_PROGRESS_DATA'})
		const {data} = await axios.get(url, {withCredentials: true})
		dispatch({type: 'FETCH_PROGRESS_DATA', payload: {stats: data.stats}})
	} 
	catch(e: any) {
		console.log(e.message)
		const message = `Error message: ${e.message}`
		dispatch(throwErrorAction(message))
	}
}