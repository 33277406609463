import axios from 'axios'


const customFetch = axios.create({
  //baseURL: 'https://eartrainer-backend.onrender.com/api/v1/tests',
  baseURL: 'https://earmentor.onrender.com/api/v1/tests',
})

export const customAuthFetch = axios.create({
  //baseURL: 'https://eartrainer-backend.onrender.com/api/v1/tests',
  baseURL: 'https://earmentor.onrender.com/api/v1/auth',
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
})


export default customFetch