import styled from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {useState, useEffect} from 'react'
import LoadingGif from './LoadingGif'
import { searchProgressDataAction } from '../actions/progressStatsAction'
import { throwErrorAction } from '../actions/errorAction'
import customFetch from '../axios';
import {UserDataStyles} from '../wrappers/UserDataWrapper'


const UserDataPage = () => {
  const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
  const {isLoading, stats} = useSelector((state: AppStateType) => state.progress)
  const [currentLevel, setCurrentLevel] = useState(1)
  const [totalTests, setTotalTests] = useState(0)
  const [totalAnswers, setTotalAnswers] = useState(0)
  const [successRate, setSuccessRate] = useState(0)
  let index = 0

  const totalsHandler = async() => {
    try {
      const {data} = await customFetch.get(`/get-totals`, {
        withCredentials: true
      })
      const {totalTests: tests, answers} = data
      setTotalTests(tests)
      setTotalAnswers(answers)
      const rate = Math.round(answers / (tests * 20) * 100)
      setSuccessRate(rate)
    } catch(error: any) {
      const dataMsg: string = error.response?.data?.msg
      if (dataMsg) {
        dispatch(throwErrorAction(dataMsg))
      } else {
      const message = `Error message: ${error.message}`
      dispatch(throwErrorAction(message))        
      }
    }
  }

  useEffect(() => {
    const sum = +stats.tech + +stats.harmonic + +stats.rhythm
    setCurrentLevel(Math.floor(sum / 3))
  }, [stats])


  useEffect(() => {
    dispatch(searchProgressDataAction())
    totalsHandler()
  }, [dispatch])

  const rows = [
    {name: 'Completed tests', quantity: totalTests, description: false},
    {name: 'Correct answers', quantity: totalAnswers === 0 ? '0' : `${totalAnswers} (${successRate}%)`, description: false},
    {name: 'Technique module', quantity: `${stats.tech} / 10`, description: true},
    {name: 'Harmonic module', quantity: `${stats.harmonic} / 10`, description: true},
    {name: 'Timing module', quantity: `${stats.rhythm} / 10`, description: true},
    {name: 'Current level', quantity: `${currentLevel}`, description: true},
    ]

  return(
    <UserDataStyles>
      <h3 className="main-header">Stats</h3>
      { isLoading ? <div className="loader-container"><LoadingGif/></div> :
      <><TableContainer className="table-box" component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name} {row.description && <sup>{++index}</sup>}
                </TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="sup-description">
       <p><sup>1, 2, 3</sup> computed based on difficulty of solved tests</p>
       <p><sup>4</sup> computed based on the arithmetic average of solved tests difficulty</p>
      </div></>}
    </UserDataStyles>
    )
}

export default UserDataPage
