import bgimage from '../img/image_processing20200212-1116-d0f1ir-min.png'
import {AboutStyles} from '../wrappers/AboutTest'
import { HashLink } from 'react-router-hash-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpLong } from '@fortawesome/free-solid-svg-icons'
import { scrollHook } from '../lib/scrollHook'

//main component
function AboutProject() {
	const upIcon = <FontAwesomeIcon icon={faArrowUpLong} />

	return(
	 	<AboutStyles id="about">
	 	<div className="main-block">
	 		<h2>About</h2>
	 		<p>Lack of practice in aural skills is the biggest challenge for all beginner musicians. 
	 		Not everyone has the passion or time to develop melodic, rhythmic, or technical ear skills.</p>
	 		<p>As a result, difficulties arise in playing by ear and the inability to identify and correct one's own technical mistakes. 
	 		Issues such as playing <i>'out of sync,' rushing, or producing overtones from neighboring strings</i> primarily stem from a lack of practice in aural skills rather than flaws in technical abilities.</p>
	 		<p>The <i>main objective</i> of this application is to assist students in integrating musical ear training into their daily practice sessions.</p>
	 		<p>By dedicating 20-30 minutes to these tests several times a week, you will begin to approach the practice of your instrument in a completely different manner.</p>
	 		<div className="button-container">
	 			<HashLink scroll={(el) => scrollHook(el)} to="/#tests"><button className="main-button">Start practice {upIcon}</button></HashLink>
	 		</div>
	 	</div>
	 	<div className="image-block">
	 		<img src={bgimage} alt="eartrainer"/>
	 	</div>
	 	</AboutStyles>	
		)
}


export default AboutProject;