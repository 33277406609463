import {ButtonStyles, NextButtonStyles} from '../wrappers/MainTestButtonStyle'
import {CurrentTestButtonType, SkipTestButtonType, FinishTestButtonType} from '../types'

//main component
function MainTestButton({answer, isAnswered, newAnswerHandler}: CurrentTestButtonType) {
	return(
			<ButtonStyles
				data-tooltip="Select answer"
				onMouseUp={(e) => e.preventDefault()} 
				onClick={(e: React.MouseEvent<HTMLButtonElement>) => answer !== '' 
				? newAnswerHandler() : (e.target as HTMLButtonElement).classList.toggle('tooltip-active')}
				className={`main-button ${answer === '' ? "inactive" : ""}`}>{isAnswered 
					? "Change answer" 
					: "Answer"}
	  </ButtonStyles>
		)
}

//skip button
export function NextTestButton({isAnswered, currentIndex, changeSingleTest}: SkipTestButtonType) {
	return(
		<NextButtonStyles 
			className="simple-button" 
			onClick={() => changeSingleTest(currentIndex + 1)}>{isAnswered 
				? "Next"
				: "Skip"}
		</NextButtonStyles>
		)
}

//finished button
export function TestFinishButton({answer, finishTestHandler}: FinishTestButtonType) {
	return(
		<ButtonStyles
		data-tooltip="Select answer"
		onMouseUp={(e) => e.preventDefault()} 
		className={`main-button ${answer === '' ? "inactive" : ""}`}
		onClick={(e: React.MouseEvent<HTMLButtonElement>) => answer !== '' 
		? finishTestHandler() : (e.target as HTMLButtonElement).classList.toggle('tooltip-active')}>Complete test!</ButtonStyles>
		)
}



export default MainTestButton;










