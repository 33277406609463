export function formatDate(dateString: string): string {
  const regex: RegExp = /^(\d{4})-(\d{2})-(\d{2}).*/
  const [, year, month, day] = regex.exec(dateString)!

  const months: string[] = [
    'jan', 'feb', 'mar', 'apr', 'may', 'jun',
    'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
  ];

  // Create a new date string in the desired format
  const formattedDate: string = `${day} ${months[parseInt(month, 10) - 1]} ${year}`;

  return formattedDate
}

