const InitialState = {
	'answers': [
		{
			'isCorrect': false,
			'correct': '',
			'audio': '',
			'answer': '',
			'question': ''
		}
	],
	'succeededTests': 0,
	'testId': '',
	'isLoading': true
}


const finishedTestReducer = (state = InitialState, action: any) => {
	switch(action.type) {
		case 'FETCH_ANSWERS' : {
			return {
				answers: action.payload.answers,
				succeededTests: action.payload.succeededTests,
				testId: action.payload.testId,
				isLoading: false
			}
		}
		case 'RESET_FINISHED_ANDSERS' : {
			return {
				...InitialState
			}
		}
		default : {
			return {
				...state
			}
		}
	}
}

export default finishedTestReducer