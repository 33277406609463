import styled from 'styled-components'

export const PlayerStyle = styled.div`
.player {
 display: flex;
 flex-direction: row;
 align-items: center;
 padding: 2rem 0;
 border: 2px solid #f1f3f4;
 background-color: #f1f3f4;
 border-radius: 5px;
 width: 50%;
 margin-bottom: 1rem;	
}

.time-control {
  width: 75%;
  display: flex;
  align-items: center;
	input {
    width: 100%;
    -webkit-appearance: none;
    background: transparent;
    cursor: col-resize;
	}
	p {
		padding: 1.3rem;
	}
}

.play-control {
	position: relative;
	width: 15%;
	display: flex;
	justify-content: space-around;
  &.speed-control span{
    font-weight: 700;
    color: #6B7AA1;
    border-bottom: 2px solid #6B7AA1;
    font-size: .8rem;
    &:hover {
      cursor: pointer;
    }
  }
	svg {
		color: #6B7AA1;
		cursor: pointer;
		transition: color 150ms;
		&:hover {
			color: #97A1BC
		}
	}
	.loading-icon {
		animation: loading 1s linear infinite;
	}
}

.time-counter {
	width: 15%;
	text-align: center;
}

.selected {
	background-color: rgba(130, 109, 166, 0.5) !important;
  span {
    opacity: 1 !important;
  }
}

.track {
  width: 100%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  height: 1rem;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  background: blue;
}
.animate-track {
  background: rgb(204, 204, 204);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  padding: 1rem;
  pointer-events: none;
  transition: transform 0ms linear;
  cursor: col-resize;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  opacity: 0;
  height: 16px;
  width: 16px;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  opacity: 0;
  background: transparent;
  border: none;
}

input[type="range"]::-ms-thumb {
  opacity:0;
}

@keyframes loading {
 0% { 
   transform: rotate(0); 
 }
 100% { 
   transform: rotate(360deg);
 }
}

@media screen and (max-width: 900px) {
 .player {
   padding: 1rem 0;
   width: 80%;
 }

 .play-control.speed-control span {
   font-size: .7rem;
 }
}

@media screen and (max-width: 768px) {
	.player {
		width: 100%;
		padding: 1rem 0;
	}
  .time-control {
    width: 80%;
    p {
      padding: 0.8rem;
    }
  }

  .play-control {
    width:15%;
  }

  .time-counter {
    width: 20%;
  }
} 
`