import styled from 'styled-components'

export const UserHistoryStyles = styled.div`
  padding-top: 1.5rem; 
  padding-bottom: 1.5rem;
  
  .simple-button.inverse {
    margin-top: 3.5rem;
  }

  h3.main-header {
    &:after {
      opacity: .6;
      margin-top: .5rem;
      content: '';
      display: block;
      width: 4rem;
      height: 2px;
      background-color: #EF4F4F;
    }
  }

  .tests-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;

    p {
      a {
        text-decoration: underline;
        color: #4855ab;
        font-weight: 700;
      }
    }

     .test-item {
      display: flex;
      position: relative;
      align-items: stretch;
      flex-direction: column;
      padding: 1rem;
      padding-bottom: 2.8rem;
      width: 30%;
      background-color: #fff;
      margin-right: 1rem;
      margin-bottom: 1rem;
      border: 1px solid #f1f3f4;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1); 
      transition: all 200ms ease-in;
      &: hover {
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      } 
      p {
        font-size: .8rem;
      } 
      a {
        color: #97A1BC;
        font-weight: 700;
        font-size: .8rem;
        position: absolute;
        bottom: 1rem;
        left: 0;
        transform: translateX(1rem);
      }
      .test-technique {
        svg {
          color: #97A1BC;
        }
      }  
    }
  }  


  @media (max-width: 768px) {
    .tests-list {
      .test-item {
        width: 45%;
      }
    }   
  }

  @media (max-width: 515px) {
    .tests-list {
      .test-item {
        width: 100%;
        margin-right: 0;
      }
    } 
    .simple-button.inverse {
      width: 100%;
    }
    h3.main-header {
      text-align: center;
      &:after {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`

export const DeleteModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 2px solid #000;
  text-align: center;
  padding: 2rem 4rem;

  p, span {
    margin-bottom: 2rem;
    display: block;
  }

  span {
    color: #EF4F4F;
  }

  button:nth-child(1) {
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    padding: 1rem 1rem 2rem 1rem;
    width: 90%;
    button:nth-child(1) {
      margin-right: 0;
      margin-bottom: .5rem;
    }  
    button {
      width: 100%;
    }
  }
`