import {Link} from "react-router-dom";
import {faEarListen} from '@fortawesome/free-solid-svg-icons';
import {HashLink} from 'react-router-hash-link';
import {NavStyle, Logo} from '../wrappers/NavStyles'
import Autocomplete from '@mui/material/Autocomplete';
import {searchTestAction} from '../actions/testsInfoAction'
import {useDispatch, useSelector} from 'react-redux'
import {AppStateType} from '../reducers/index'
import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {useState, useEffect} from 'react'
import {useLocation, useNavigate} from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserAlt} from '@fortawesome/free-solid-svg-icons'
import { scrollHook } from '../lib/scrollHook';
import { getTechiqueName } from '../lib/getTechique';
import { LoadingGifSmall } from './LoadingGif'

function Nav() {
  const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
  const tests = ['sync', 'technique', 'timing', 'chords', 'rhythm', 'intervals']
  const [testName, setTestName] = useState<string | null>(null)
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const user = useSelector((state: AppStateType) => state.user)
  const logoEarIcon = <FontAwesomeIcon icon={faEarListen}/>
  const userIcon = <FontAwesomeIcon icon={faUserAlt} />

  useEffect(() => {
    function inputHandler(test: string | null): void {
      const str = test !== null ? getTechiqueName(test) : ''
      dispatch(searchTestAction({name: str}))
      navigate('/#tests')
      setTestName(null)
    }
    if (testName?.length !== 0 && testName !== null) {
      inputHandler(testName)
    }
  }, [testName, dispatch, navigate, pathname])

 return (
  <NavStyle>
    <Link to="/" onClick={() => dispatch(searchTestAction({}))}>
    <Logo>
      <span>{logoEarIcon}</span>
      <span>Earmentor</span>
    </Logo>
    </Link>
     <label>
      <Autocomplete
        sx={{
          display: 'inline-block',
          '& input': {
            bgcolor: 'background.paper',
            color: (theme) =>
              theme.palette.getContrastText(theme.palette.background.paper),
          },
        }}
        id="custom-input-demo"
        options={tests}
        value={testName}
        onChange={(event: any, newValue: string | null) => {
          setTestName(newValue)
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input type="text" {...params.inputProps} 
            placeholder="&#xF002;  search tests" 
            style={{fontFamily: "Montserrat, FontAwesome"}} />
          </div>
        )}
      />
    </label>

    <div>
      <ul>
        <li><HashLink scroll={(el) => scrollHook(el)}  to="/#donate">Pricing</HashLink></li> 
        <li><Link to="/#tests" onClick={() => dispatch(searchTestAction({}))}>Tests</Link></li>         
        {
          user.isLogedIn 
          ? <li><Link to={`/user/my-page/dashboard`}>{user.name} {userIcon}</Link></li>
          : <>
            <li><Link to="/login">Login</Link></li>
            <li className="sign-up"><Link to="/register">Sign up</Link></li>
          </>
        }
      </ul>          
     </div>
  </NavStyle>
 );
}

export default Nav;