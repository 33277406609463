import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {AppStateType} from '../reducers/index'

export const setAlert = (message: string) => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	dispatch({type: 'SET_ALERT', payload: {message}})
}

export const resetAlert = () => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	dispatch({type: 'RESET_ALERT'})
}