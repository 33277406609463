import styled from 'styled-components'

export const DashboardStyles = styled.div`
  display: flex; 
  align-items: center;
  padding-top: 1.5rem; 
  padding-bottom: 1.5rem;
  .chart {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      align-self: flex-start;
    }
    .axis-label {
      font-style: italic;
    }
  }

  h3.main-header {
    &:after {
      opacity: .6;
      margin-top: .5rem;
      content: '';
      display: block;
      width: 4rem;
      height: 2px;
      background-color: #EF4F4F;
    }
  }

  .progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    text {
      font-weight: 700;
    }
    p {
      margin-bottom: -1.5rem;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .chart {
      width: 100%;
    }

    .progress-bar {
      width: 100%;
      padding-top: 2rem;
      text {
        font-size: .8rem;
      }
    }
  }

  @media (max-width: 515px) {
    h3.main-header {
      text-align: center;
      align-self: center;
      &:after {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`