export const slugTechniqueMap = new Map([
		['melodic-intervals-1', '#97A1BC'],
		['melodic-intervals-2', '#97A1BC'],
		['sync-1', '#BB95B2'],
		['sync-2', '#BB95B2'],
		['rhythm-1', '#eda29e'],
		['rhythm-0', '#eda29e'],
	])


export default function getColor(slug: string) {
	if(!slugTechniqueMap.has(slug)) {
		return '#97A1BC'
	}
	return slugTechniqueMap.get(slug)
}