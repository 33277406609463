import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {AppStateType} from '../reducers/index'
import customFetch from '../axios'
import axios from 'axios'
import {throwErrorAction} from './errorAction'


export const allTestsAction = () => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	try {
		dispatch({type: 'LOADING_DATA'})
		const {data} = await customFetch.get('/')
		dispatch({type: 'FETCH_ALL_TESTS', payload: {data}})
	} 
	catch(e) {
		console.log(e)
	}
}


export const searchTestAction = (body: any) => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	const url = 'https://earmentor.onrender.com/api/v1/tests/'

	try {
		dispatch({type: 'LOADING_DATA'})
		const {data} = await axios.get(url, {params: {...body}})
		dispatch({type: 'FETCH_ALL_TESTS', payload: {tests: data.tests, totalTests: data.totalTests}})
	} 
	catch(e: any) {
		console.log(e.message)
		const message = `Error message: ${e.message}`
		dispatch(throwErrorAction(message))
	}
}