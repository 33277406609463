import {FormPage} from '../wrappers/FormPageWrapper'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import {useState, useEffect} from 'react'
import { validatePassword, validateEmail } from '../lib/validateData'
import Alert from '@mui/material/Alert'
import {Link} from "react-router-dom"
import {customAuthFetch} from '../axios'
//@ts-ignore
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {throwErrorAction} from '../actions/errorAction'
import {useErrorHandler} from '../authApi'


interface IForgotValues {
	email: string,
}

function ForgotPassword() {
	const initialValues = {
		email: '',		
	}
	const [values, setValues] = useState<IForgotValues>(initialValues)
	const [inputError, setInputError] = useState<boolean>(false)
	const [alert, setAlert] = useState<string>('')
	const [alertType, setAlertType] = useState<any>('success')
	const [loading, setLoading] = useState<boolean>(false)
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()

  const handleChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const validateData = (values: IForgotValues) => {
  	if(!validateEmail(values.email)) {
			setInputError(true)
			return false
  	} 
  	return true
  }

  const formSubmit = async (e: any) => {
  	e.preventDefault()
   	setLoading(true)
  	nprogress.start()
  	setAlert('')
  	try {
	  	if (validateData(values)) {
	  		const {data} = await customAuthFetch.post('/forgot-password', values)
		  	setInputError(false)
		  	setValues(initialValues)
		  	setAlert('Success! Check your email to create a new password.') 		
	  	}
 	 	} catch(error: any) {
			const dataMsg: string = error.response?.data?.msg
			if (dataMsg) {
				dispatch(throwErrorAction(dataMsg))
			} else {
			const message = `Error message: ${error.message}`
			dispatch(throwErrorAction(message))  			
			}
  		setAlertType('error')
  		setAlert('Error!')	
 	 	}
  	setLoading(false)
  	nprogress.done()
  }

  useEffect(() => {
  	if(alert.length > 0) {
  	const timerId = setTimeout(() => {
  		setAlert('')
  	}, 3000)
  	return () => clearTimeout(timerId)
  	}
  }, [alert])

	return(
	 	<FormPage>
		  <Box
		  	className="loginForm"
		    component="form"
		    sx={{
		      '& .MuiTextField-root': { m: 0, width: '100%' },
		    }}
		    noValidate
		    onSubmit={formSubmit}
		  >
			  <div>
			  	{alert.length > 0 && <Alert severity={alertType} className="alert-message">{alert}</Alert>}
			  	<h3>Enter your email</h3>
	        <TextField
	        	error={inputError ? true : false}
	        	autoComplete="email"
	        	color="secondary"
	          required
	          fullWidth
	          name="email"
	          label="Email"
	          type="email"
	          size="small"
	          helperText={inputError ? "Enter a valid email" : ""}
	          value={values.email}
	          onChange={handleChange}
	          InputLabelProps={{
	            shrink: true,
	          }}
	        />
			  </div>
        <button 
        type='submit'
        className="simple-button inverse">
        	Reset password
        </button>
		  </Box>
	 	</FormPage>	
		)
}


export default ForgotPassword