import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {AppStateType} from '../reducers/index'
import customFetch from '../axios'
import {throwErrorAction} from './errorAction'
import {testsResultType, answerTypeRecords} from '../types'


export const finishedTestAction = (testId: string, answerList: answerTypeRecords) => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	dispatch({type: 'RESET_FINISHED_ANDSERS'})
	const reqBody = {answerList, testId}
	try {
		const {data} = await customFetch.post('/compare', reqBody, 
			{ headers: { "Content-Type": "application/json" },
			withCredentials: true })
		const {result, succeededTests} = data
		dispatch({
		type: 'FETCH_ANSWERS',
		payload: {
			answers: result,
			succeededTests: succeededTests,
			testId: testId
		}
	})
	} 
	catch(e: any) {
		console.log(e.message)
		const message = `Error message: ${e.message}`
		dispatch(throwErrorAction(message))
	}
}


export const finishedTestReset = () => (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	dispatch({type: 'RESET_FINISHED_ANDSERS'})
}


export default finishedTestAction




