import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
import styled from 'styled-components'
import bgimageGrad from '../img/grad-9.png'
import bgImageMin from '../img/grad-9-min.png'
import {useEffect, useState} from 'react'
import {FirstScreen} from '../wrappers/MainPageStyles'
import { scrollHook } from '../lib/scrollHook';

function MainPage() {
	const downIcon = <FontAwesomeIcon icon={faArrowDownLong} />
	const [imageLoaded, setImageLoaded] = useState(false)
	const bgimageGrad2 = new Image()
	bgimageGrad2.src = bgimageGrad



 useEffect(() => {
 	if (bgimageGrad2.complete) {
 		setImageLoaded(true)
 	}
 	//bgimageGrad2.onload = () => setImageLoaded(true)
 },[bgimageGrad2])  

	return(
	 	<FirstScreen>
		 	<div className="main-block" style={{ backgroundImage:`url(${imageLoaded ? bgimageGrad2.src : bgImageMin})` }}>
		 		<p>Earmentor App</p>
		 		<h3><span>A complete and systematic approach</span> <br/> to improving your musical skills</h3>
		 		<HashLink scroll={(el) => scrollHook(el)} to="/#tests"><button className="main-button">Practice {downIcon}</button></HashLink>
		 	</div>
	 	</FirstScreen>	
		)
}


export default MainPage;