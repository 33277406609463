import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {AppStateType} from '../reducers/index'
import {shuffleArray} from '../lib/shuffle'
import customFetch from '../axios'
import {throwErrorAction} from './errorAction'


export const currentTestAction = (id: string) => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	dispatch({type: 'RESET_CURRENT_TEST'})
	try {
		const {data} = await customFetch.get(`/${id}`)
		let tests = data.tests
		tests = shuffleArray(tests)
		dispatch({
			type: 'FETCH_CURRENT_TEST',
			payload: {
					'testId': data.testId,
					'tests': tests
			}
		})
	}
	catch(e: any) {
		console.log(e.message)
		const message = `Error message: ${e.message}`
		dispatch(throwErrorAction(message))
	}
}


export const resetCurrentTestAction = () => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	dispatch({type: 'RESET_CURRENT_TEST'})
}