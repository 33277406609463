import styled from 'styled-components'



export const DonateStyles = styled.div`
 position: relative;
 overflow: hidden;
	.euqalizer {
		 position: absolute;
		 bottom: 0;
		 left: 0;
		 width: 100%;
		 z-index: -1;
	}
&.container {
	 min-height: calc(100vh - 6rem); 
}
	.main-block {
		padding: 1rem 0 2rem 0;
		text-align: center;
		h2.main-header {
			display: inline-block;
			&:after {
				opacity: .6;
				margin-top: .5rem;
				margin-left: auto;
				margin-right: auto;
				content: '';
				display: block;
				width: 4rem;
				height: 3px;
				background-color: #EF4F4F;
			}
		}


		.subscribes {
			padding-top: 1.5rem;
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
			grid-column-gap: 1.5rem;
			grid-row-gap: 1.5rem;
			grid-auto-rows: 1fr;			
			.subscribe-item {
					h2, h3 {
						opacity: .8;	
					}

					h2 {
						font-weight: 500;
						opacity: .7;	
						sub {
							font-size: 1.3rem;
								opacity: .4;	
						}
					}

				 border: 2px solid #e9e8e8;
				 border-radius: .5rem;
				 padding: 0 2rem 2rem 2rem;
				 .simple-button {
				 	width: 100%; 
				 	border-radius: 3px;
				 }

				 ul {
				 	padding-bottom: 2rem;
				 	text-align: left;
				 	list-style: none;

				 	li {
				 		position: relative;
				 		line-height: 22px;
				 		padding-left: 15px;
				 		opacity: .7;
				 		font-size: .9rem;
				 	}

				 	li:before {
			    content: '';
			    width: 4px;
			    height: 4px;
			    top: 10px;
			    left: 0;
			    position: absolute;
			    background-color: #ec4743;
				 }
			}
		}
	}
}


	@media (max-width: 768px) {
		.main-block {
			padding: 2rem 0;
			.subscribes {
				.subscribe-item {
					padding: 0 1.5rem 1.5rem 1.5rem;

					ul li {
						font-size: .7rem;
					}
				}
			}
		}
	}

	@media (max-width: 415px) {
		.main-block {
			padding: 2rem 0;
			h3.main-header {
				&:after {
					width: 4rem;
				}
		}
	}
`