import {useState, useEffect} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlay, faPause} from '@fortawesome/free-solid-svg-icons'
import {loadingDisc} from './TestPlayer'
//@ts-ignore
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {PlayerStyle} from '../wrappers/SampleTestPlayerStyles'


//main component
function SampleTestPlayer({audioRef, currentAudio, playingAudio, setPlayingAudio}: any) {
	const [isPlaying, setPlaying] = useState<boolean>(false)
	const [audioLoading, setLoading] = useState<boolean>(false)
	const [slowMode, setSlowMode] = useState(false)

	//icons
	const playIcon = <FontAwesomeIcon size="2x" className="play" icon={faPlay} />
	const pauseIcon = <FontAwesomeIcon size="2x" className="pause" icon={faPause} />

//pause any audio after press play button
	useEffect(() => {
	if (playingAudio !== currentAudio) {
			setPlaying(false)
		}
// eslint-disable-next-line
	}, [playingAudio])

//if playing audio - change playback rate
	useEffect(() => {
		if (playingAudio === currentAudio) {
			if(slowMode) {
				audioRef.current.playbackRate = 0.5
			} else {
				audioRef.current.playbackRate = 1
			}			
		}
	}, [slowMode, playingAudio, currentAudio])


	//handlers
	//play current audio track
	function playSongHandler(): void {
		if (isPlaying) {
			audioRef.current.pause()
			setPlaying(false)
		} else if (!isPlaying) {
			audioRef.current.play()
			setPlaying(true)
		}
	}

	function slowModeHandler() {
		if(slowMode) {
			setSlowMode(false)
		} else {
			setSlowMode(true)
		}
	}

	//play new audio track
	async function startPlaying() {
		nprogress.start()
		setLoading(true)
		setPlaying(true)
		audioRef.current.playbackRate = !slowMode ? 1 : 0.5
		await audioRef.current.play()
		nprogress.done()
		setTimeout(() => setLoading(false), 250)
	}

	//set new playing audio
	async function setAudioHandler() {
		await setPlayingAudio(currentAudio)
		startPlaying()
	}

	return(
		<PlayerStyle>
			<div className="player">
				<div className="play-control" onClick={() => {
					currentAudio !== playingAudio 
					? setAudioHandler()
					: playSongHandler()
				}}>
				{audioLoading 
					? loadingDisc
					: isPlaying ? pauseIcon : playIcon}
				</div>
				<div className="play-control speed-control" onClick={() => slowModeHandler()}>
					<span>{slowMode ? '50%' : '100%'}
					</span>
				</div>
			</div>
		</PlayerStyle>
		)
}


export default SampleTestPlayer;