import styled from 'styled-components'


export const HomeStyle = styled.div`
	&.container {
		padding-top: 3rem;
	}

	h2.main-header {
		&:after {
			opacity: .6;
			margin-top: .5rem;
			margin-left: auto;
			margin-right: auto;
			content: '';
			display: block;
			width: 4rem;
			height: 3px;
			background-color: #EF4F4F;
		}
	}

	button.main-button.more-button {
		padding: .6rem 4rem;
		margin-top: 1.5rem;
		margin-bottom: 3rem;
		svg {
			padding-left: 1.5rem;
			animation: bounce 10s infinite;
			animation-delay: 10s;
		}
	}
	
	h2 {
		text-align: center;
	}

	.not-found-header {
		text-align: center;
	}

	.scroll-to-top {
		color: #6B7AA1;
		background-color: #f1f3f4;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
		transition: all 100ms ease-in;
  borderRadius: 24px;
		height: 3rem;
		width: 3rem;
		z-index: 9999;
  position: fixed;
  font-size: 1.6rem;
  bottom: 40px;
  right: 40px;
  text-align: center;
  &:hover {
  	background-color: #f1f3f4;
  	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);	
  }
	}

	@media (max-width: 768px) {
		&.container {
			padding-top: 2rem;
		}

		.scroll-to-top {
			font-size: 1.2rem;
			height: 2.6rem;
			width: 2.6rem;
		}
	}

	@media (max-width: 415px) {
		&.container {
			padding-top: 2rem;
		}

			button.main-button.more-button {
				width: 90%;
				margin-bottom: 2rem;
				svg {
					padding-left: .5rem;
				}
			}

	.scroll-to-top {
		font-size: 1.1rem;
		height: 2.4rem;
		width: 2.4rem;
  bottom: 25px;
  right: 25px;
	}
}
@keyframes bounce {
  0%, 4%, 10%, 16%, 20%, 100% {
    transform: translateY(0);
  }
  8% {
    transform: translateY(-6px);
  }
  12% {
    transform: translateY(-2px);
  }
}
`
export const TestList = styled.div`
	padding-top: 1.5rem;
	padding-bottom: 1rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
	grid-column-gap: 1.5rem;
	grid-row-gap: 1.5rem;
	grid-auto-rows: 1fr;
	
	@media (max-width: 415px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		padding-top: 0;
	}
`

