import {FormPage} from '../wrappers/FormPageWrapper'
import Alert from '@mui/material/Alert'
import {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {customAuthFetch} from '../axios'
//@ts-ignore
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {throwErrorAction} from '../actions/errorAction'
import {AppStateType} from '../reducers/index'
import {Link} from "react-router-dom"
import {useErrorHandler} from '../authApi'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function VerifyEmail() {
	const [alert, setAlert] = useState<string>('')
	const [alertType, setAlertType] = useState<any>('success')
	const query = useQuery()
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
	const loginLink = <Link to="/login">login</Link>
	const navigate = useNavigate()

	const verifyTokenRequest = async () => {
    nprogress.start()
    const verificationToken = query.get('verificationToken')
    const email = query.get('email')
    const verificationLink = `?verificationToken=${verificationToken}&email=${email}`
    try {
      const {data} = await customAuthFetch.post(`/verify-email${verificationLink}`)
      if (data) {
      	setAlertType('success')
      	setAlert(`Your email is verified! Now you can `)
      }      
    } catch (error: any) {
			const dataMsg: string = error.response?.data?.msg
			if (dataMsg) {
				dispatch(throwErrorAction(dataMsg))
			} else {
			const message = `Error message: ${error.message}`
			dispatch(throwErrorAction(message))  			
			}
  		setAlertType('error')
  		setAlert('Verification error! Try again later.')	
    }
    nprogress.done()
	}

	useEffect(() => {
		verifyTokenRequest()
	}, [])

	return(
		<FormPage>
			<div className="loginForm verify-page">
				{alert.length > 0 && <Alert severity={alertType} className="alert-message">{alert}
				{alertType === 'success' ? loginLink : null}</Alert>}
			</div>
		</FormPage>
		)
}