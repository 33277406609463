import {SearchBoxStyles} from '../wrappers/SearchBoxWrapper'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {useState, useEffect} from 'react'
import {searchTestAction} from '../actions/testsInfoAction'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {setAlert} from '../actions/infoAlertAction'

function SearchBox({total}: {total: number}) {
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
	const [bodyRequest, setBody] = useState<object>({})

	const optionsTech = ['All', 'Harmonic', 'Rhythm', 'Technique']
	const [technique, setTech] = useState<string | null>('')

	const optionsLevel = ['All', 'Beginner', 'intermediate', 'Advanced']
	const [complexity, setCompl] = useState<string | null>('')

	const optionsSorting = ['New first', 'Old first', 'Easy first', 'Hard first']
	const [sorting, setSorting] = useState<string | null>('')


	function inputHandler(body: any) {
   dispatch(searchTestAction(body))
	}

	useEffect(() => {
		if (Object.keys(bodyRequest).length !== 0) {
			inputHandler(bodyRequest)
		}
	}, [bodyRequest])


	return(
		<SearchBoxStyles>
			<FormControl fullWidth size="small" className="grid-select">
			 <InputLabel id="select-technique">Technique</InputLabel>
			 <Select
			 		className="custom-input"
			   labelId="select-technique"
			   id="demo-simple-select"
			   value={technique}
			   label="Technique"
			   onChange={(event: any) => {
			   	setTech(event.target.value)
			   	setBody({...bodyRequest,	technique: event.target.value})
			   }}
			 >
				 {optionsTech.map(item => {
				 	return <MenuItem key={`${item}`} value={item}>{`${item}`}</MenuItem>
				 })}		 		
			 </Select>
			</FormControl>

			<FormControl fullWidth size="small" className="grid-select">
			 <InputLabel id="select-complexity">Level</InputLabel>
			 <Select
			 		className="custom-input"
			   labelId="select-complexity"
			   id="demo-simple-select"
			   value={complexity}
			   label="Level"
			   onChange={(event: any) => {
			   	setCompl(event.target.value)
			   	setBody({...bodyRequest,	complexity: event.target.value})
			   }}
			 >
			 	{optionsLevel.map(item => {
				 	return <MenuItem key={`${item}`} value={item}>{`${item}`}</MenuItem>
				 })}		  
			 </Select>
			</FormControl>

			<FormControl fullWidth size="small" className="grid-select">
			 <InputLabel id="select-sorting">Sort</InputLabel>
			 <Select
			 		className="custom-input"
			   labelId="select-sorting"
			   id="demo-simple-select"
			   value={sorting}
			   label="Sort"
			   onChange={(event: any) => {
			   	setSorting(event.target.value)
			   	setBody({...bodyRequest,	sorting: event.target.value})
			   }}
			 >
			 	{optionsSorting.map(item => {
				 	return <MenuItem key={`${item}`} value={item}>{`${item}`}</MenuItem>
				 })}		
			 </Select>
			</FormControl>
		</SearchBoxStyles>
		)
}



export default SearchBox