const InitialState = {
	'errorMessage': null
}


const errorReducer = (state = InitialState, action: any) => {
	switch(action.type) {
		case 'THROW_ERROR' : {
			return {
				errorMessage: action.payload.error
			}
		}
		case 'CLEAR_ERROR' : {
			return {
				...InitialState
			}
		}
		default : {
			return {
				...state
			}
		}
	}
}

export default errorReducer