import {FormPage} from '../wrappers/FormPageWrapper'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import {useState, useEffect, useRef} from 'react'
import { validatePassword, validateEmail } from '../lib/validateData'
import Alert, {AlertColor} from '@mui/material/Alert'
import {Link} from "react-router-dom"
//@ts-ignore
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {customAuthFetch} from '../axios'
import axios from 'axios'
import {setNewUser} from '../actions/userAction'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {throwErrorAction} from '../actions/errorAction'
import {useNavigate} from "react-router-dom"
import Button from '@mui/material/Button'
import {useErrorHandler} from '../authApi'
import { LoadingGifSmall } from './LoadingGif'

interface ILoginValues {
	email: string,
	password: string
}

function LoginPage() {
	const initialValues = {
		email: '',
		password: ''		
	}
	const [values, setValues] = useState<ILoginValues>(initialValues)
	const [inputError, setInputError] = useState<boolean>(false)
	const [passwordError, setPasswordError] = useState<boolean>(false)
	const [alert, setAlertValue] = useState<string>('')
	const [alertType, setAlertType] = useState<any>('success')
	const [loading, setLoading] = useState<boolean>(false)
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
	const navigate = useNavigate()
	const {isLogedIn, name, id} = useSelector((state: AppStateType) => state.user)

  const handleChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const validateData = (values: ILoginValues, testUser: boolean) => {
  	if(testUser) {
  		return true
  	}
  	if(!validatePassword(values.password)) {
  		setPasswordError(true)
  		setLoading(false)
  		return false
  	} 
  	if(!validateEmail(values.email)) {
			setInputError(true)
			setLoading(false)
			return false
  	} 
  	return true
  }

  const formSubmit = async (e: any) => {
  	e.preventDefault()
  	setLoading(true)
  	nprogress.start()
  	setAlertValue('')
  	const testUser = e.nativeEvent.submitter.textContent === 'Demo Login'
  	try {
			if (validateData(values, testUser)) {
				const {data} = !testUser 
				? await customAuthFetch.post('/login', values)
				//only for testing purposes
				: await customAuthFetch.post('/login', {email: 'test@gmail.com', password: 'e4e5d4d6'})
		   	setPasswordError(false)
		  	setInputError(false)
		  	dispatch(setNewUser(data.user))
		  	setAlertType('success')
		  	setAlertValue('Done!')
			}
  	} catch(error: any) {
			const dataMsg: string = error.response?.data?.msg
			if (dataMsg) {
				dispatch(throwErrorAction(dataMsg))
			} else {
			const message = `Error message: ${error.message}`
			dispatch(throwErrorAction(message))  			
			}
  		setAlertType('error')
  		setAlertValue('Error!')
  		setLoading(false)	
  	}
  	nprogress.done()
  }

  useEffect(() => {
  	if(isLogedIn) {
  	const timerId = setTimeout(() => {
	  	navigate(`/user/my-page/dashboard`)  
	  	setLoading(false)		
  	}, 1000)
  	return () => clearTimeout(timerId)
  	}
  }, [isLogedIn, dispatch])

	return(
	 	<FormPage>
		  <Box
		  	className="loginForm"
		    component="form"
		    sx={{
		      '& .MuiTextField-root': { m: 0, width: '100%' },
		    }}
		    noValidate
		    autoComplete="off"
		    onSubmit={formSubmit}
		  >
			  <div>
			  	{alert.length > 0 && <Alert severity={alertType} className="alert-message">{alert}</Alert>}
			  	<h3>Enter email and password</h3>
	        <TextField
	        	autoComplete="email"
	        	error={inputError ? true : false}
	        	color="secondary"
	          required
	          fullWidth
	          name="email"
	          label="Email"
	          type="email"
	          size="small"
	          helperText={inputError ? "Enter a valid email" : ""}
	          value={values.email}
	          onChange={handleChange}
	          InputLabelProps={{
	            shrink: true,
	          }}
	        />
	        <TextField
	        	autoComplete="password"
	        	error={passwordError ? true : false}
	        	color="secondary"
	        	required
	        	fullWidth
	        	name="password"
	          label="Password"
	          type="password"
	          size="small"
	          helperText={passwordError ? "Password must contain 6 characters or more" : ""}
	          value={values.password}
	          onChange={handleChange}
	          InputLabelProps={{
	            shrink: true,
	          }}
	        />
	        <div className="form-description forgot">
	        	<Link to="/forgot-password">Forgot password?</Link>
	        </div>
			  </div>
			  <button 
        className="simple-button inverse demo-button">
        	Demo Login
        </button>
				{loading ? <button className="simple-button"><LoadingGifSmall color=""/></button>
				:<button 
        type='submit'
        className="simple-button inverse">
        	Login
        </button>}
        <div className="form-description">New user?
        <br/>
        <Link className="register-link" to="/register">Create an account</Link>      
        </div>
		  </Box>
	 	</FormPage>	
		)
}


export default LoginPage