import styled from 'styled-components'

export const PlayerStyle = styled.div`
.player {
 display: flex;
 flex-direction: row;
 align-items: center;
 padding: 1.2rem .5rem;
 border: 2px solid #f1f3f4;
 background-color: #f1f3f4;
	border-radius: 5px;
	width: 20%;
}

.play-control {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	font-size: 1.4rem;
	&.speed-control {
		span {
	    font-weight: 700;
	    color: #6B7AA1;
	    border-bottom: 2px solid #6B7AA1;
	    font-size: .8rem;
	    &:hover {
	      cursor: pointer;
	    }			
		}
	}
	svg {
		color: #6B7AA1;
		cursor: pointer;
		transition: color 150ms;
		&:hover {
			color: #97A1BC
		}
	}

	.loading-icon {
		animation: loading 1s linear infinite;
	}
}

@keyframes loading {
 0% { 
   transform: rotate(0); 
 }
 100% { 
   transform: rotate(360deg);
 }
}

@media screen and (max-width: 768px) {
	.player {
		width: 100%;
		padding: .5rem 0;
	}
  .time-control {
    width: 80%;
    p {
      padding: 0.8rem;
    }
  }

  .play-control {
    width:15%;
    font-size: 1.1rem;
  }

  .time-counter {
    width: 20%;
  }
} 
`