import styled from 'styled-components'

export const AboutStyles = styled.div`
	display: flex;
	align-items: center;
	text-align: left;
	background-color: #FAFAFA;
	.main-block {
		width: 65%;
		padding: 0rem 5rem 0rem 5.1rem;
		h2 {
			&:after {
				opacity: .6;
				margin-top: .5rem;
				content: '';
				display: block;
				width: 4rem;
				height: 3px;
				background-color: #EF4F4F;
			}
		}
			p {
				margin-bottom: 1.2rem;
			}
		button.main-button {
			padding: .6rem 4rem;
			margin-top: 1.5rem;
			margin-bottom: 3rem;
			svg {
				padding-left: 1.5rem;
				animation: bounce 10s infinite;
				animation-delay: 10s;
			}
		}
	}
	.image-block {
		width: 35%;
		img {
			max-width: 100%;
			height: 100vh;
			object-fit: cover;
		}
	}

	@media (max-width: 900px) {
		.image-block {
			display: none;
		}	
		.main-block {
			padding: 0 3rem;
			width: 100%;
		}
	}



	@media (max-width: 768px) {
		.main-block {
			padding: 0 2rem;
			width: 100%;
			h2 {
				text-align: center;
				&:after {
					margin-top: .5rem;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
		.image-block {
			display: none;
		}
	}

	@media (max-width: 415px) {
		.image-block {
			display: none;
		}

		.main-block {
			padding: 2rem 1.5rem 3rem 1.5rem;
			width: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			button.main-button {
				width: 90%;
				margin-bottom: 2rem;
				svg {
					padding-left: .5rem;
				}
			}
			h3 {
				&:after {
					width: 4rem;
				}
			}
		}
	}

	@media (max-width: 330px) {
		padding: 0 .5rem;
	}
	@keyframes bounce {
	  0%, 4%, 10%, 16%, 20%, 100% {
	    transform: translateY(0);
	  }
	  8% {
	    transform: translateY(-6px);
	  }
	  12% {
	    transform: translateY(-2px);
	  }
	}
`