import {tasksType} from '../types'

const initialState = {
	'testId': '',
	'tests': [
		{
			'_id': '',
			'audio': '',
			'question': '',
			'answers': [
			{
				'_id': '',
				'answer': ''
			}
			]
		}
	],
	'isLoading': true
}

const currentTestReducer = (state = initialState, action: {type: string, payload: {slug: string, tests: tasksType}}) => {
	switch(action.type) {
		case 'RESET_CURRENT_TEST' : {
			return {
				...initialState
			}
		}
		case 'FETCH_CURRENT_TEST' : {
			return {
				...action.payload,
				isLoading: false,
			}
		}
		default : 
			return {
				...state,
			}
	}
}

export default currentTestReducer;