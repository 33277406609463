export const techiqueNameMap = new Map([
		['intervals', 'Intervals'],
		['rhythm', 'Timing'],
		['chords', 'Intervals'], 
		['timing', 'Timing'],
		['technique', 'Synchronization'],
		['sync', 'Synchronization'],
	])

export function getTechiqueName(technique: string) {
	const string = techiqueNameMap.get(technique)
	return string
}