import styled from 'styled-components'

export const UserDataStyles = styled.div`
  padding-top: 1.5rem;
  padding-right: .3rem;

  .sup-description {
    padding-top: 2rem;
    padding-left: .5rem;
    font-style: italic;
    p {
      font-size: .8rem;
    }
  }

  h3.main-header {
    &:after {
      opacity: .6;
      margin-top: .5rem;
      content: '';
      display: block;
      width: 4rem;
      height: 2px;
      background-color: #EF4F4F;
    }
  }

  .table-box, .sup-description {
    width: 60%;
  }

  @media (max-width: 900px) {
    .table-box, .sup-description {
      width: 70%;
    }
  }

  @media (max-width: 768px) {
    .table-box, .sup-description {
      width: 90%;
    }
  }

   @media (max-width: 515px) {
    .table-box, .sup-description {
      width: 95%;
    }
    h3.main-header {
      text-align: center;
      &:after {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`