import fullStar from '../img/star-full.png'
import emptyStar from '../img/star-empty.png'
import {Link} from "react-router-dom"
import getColor from '../lib/getColor'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRightLong} from '@fortawesome/free-solid-svg-icons'
import {TestStyle, Description, Raiting} from '../wrappers/TestStyles'
import {TestPropsTypes} from '../types'
import {formatDate} from '../lib/formatData'


function getStarRaiting(qty: number) {
	let star = []
	if (qty !== null) {
		qty = Math.floor(qty)
		for (let i = 1; i <= 10; i++) {
			if (i <= qty) {
				star.push(<img src={fullStar} key={i} alt="Full Star"/>)
			} else {
				star.push(<img className="empty-star" src={emptyStar} key={i} alt="Empty Star"/>)
			}
		}
		return star
	}
}

//main component
function Test({id, name, slug, complexity, description, quantity, technique, updatedAt}: TestPropsTypes) {
	const rightIcon = <FontAwesomeIcon icon={faArrowRightLong} />

 return (
 	<Link to={`/current-test/${id}`}>
   <TestStyle>
   	<h3>{name}</h3>
   	<Description>
   		<p style={{background: `${getColor(slug)}`}}
   		className="technique"><b>{technique}</b></p>
   		<Raiting><b className="description-header">Difficulty:</b><span>{getStarRaiting(complexity)}</span></Raiting>
   		<p><b className="description-header">Tests:</b>{quantity}</p>
   		<p><b className="description-header">Last Update:</b>{formatDate(updatedAt)}</p>
   		<p className="test-description">{description}</p>
   	</Description>
   	<button className="main-button">Practice {rightIcon}</button>
   </TestStyle>
  </Link>
 );
}

export default Test;