import {useEffect, useState} from 'react'
import Nav from './Nav'
import Home from './Home'
import CurrentTestWrapper from './CurrentTestWrapper'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import {GlobalStyle} from './GlobalStyles'
import styled from 'styled-components'
import FinishedTest from './FinishedTest'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop'
import ErrorPage from './ErrorPage'
import RegisterPage from './RegisterPage'
import VerifyEmail from './VerifyPage'
import LoginPage from './LoginPage'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import ProtectedRoute from './ProtectedRoute'
import LoadingGif from './LoadingGif'
import UserMyPage from './UserMyPage'
import Dashboard from './Dashboard'
import UserHistory from './UserHistory'
import UserDataPage from './UserData'
import UserTrainingPage from './UserTraining'
import SnackbarBox from './Snackbar'
import ErrorPageFof from './ErrorPageFof'


const AppContainer = styled.div`
`

function App() {
  return (
    <Router>
      <SnackbarBox/>
      <ErrorPage/>
      <Nav/>
      <AppContainer>
      <GlobalStyle/>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/current-test/:id" element={
          <CurrentTestWrapper />
        }/>
        <Route path="/user/my-page" element={
          <ProtectedRoute redirect="/">
            <UserMyPage> 
            </UserMyPage>
          </ProtectedRoute>
        }>
          <Route index element={<Dashboard />}/>
          <Route path="dashboard" element={<Dashboard />}/>
          <Route path="history" element={<UserHistory />}/>
          <Route path="user-info" element={<UserDataPage />}/>
          <Route path="train" element={<UserTrainingPage />}/>
          <Route path="current-test/:id" element={
            <CurrentTestWrapper />
          }/>
          <Route path="finished-test" element={<FinishedTest />}/>
        </Route>
        <Route path="/finished-test" element={<FinishedTest />}/>
        <Route path="/register" element={<RegisterPage />}/>
        <Route path="/verify-email" element={<VerifyEmail />}/>
        <Route path="/login" element={<LoginPage />}/>
        <Route path="/forgot-password" element={<ForgotPassword />}/>
        <Route path="/reset-password" element={<ResetPassword />}/>
        <Route path='*' element={<ErrorPageFof />} />
      </Routes>
      </AppContainer>
      <Footer/>
    </Router>
  )
}

export default App;
