import styled from 'styled-components'

export const TestBox = styled.div`
	padding-top: 2.5rem;

	@media (max-width: 768px) {
		padding-top: 1.5rem;
	}
	@media (max-width: 415px) {
		padding-top: 1rem;
	}
	@media (max-width: 375px) {
		padding-top: .5rem;
	}
`

export const MainTest = styled.div`
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
	border-radius: 1rem;
	border-radius: 0.2rem;
	padding: 1rem 3rem 3rem 3rem;
	h3 {
		text-align: center;
	}
	h4 {
		padding: 1rem 0rem;
	}

	@media (max-width: 768px) {
		padding: 1rem 1.2rem;
	}

	@media (max-width: 415px) {
		padding: 1rem .5rem;
	}

	@media (max-width: 375px) {
		padding: 0 .8rem 0 .8rem;

	h4 {
		padding: 0 0 .5rem 0;
	}
	}
`
export const AnswersStyle = styled.div`
.answer-box {
	padding-bottom: 1.5rem;
}
	input, label {
		cursor: pointer;
	}
	label {
		display: inline-block;
		padding: 0.5rem 0.5rem 0.2rem 0rem;
		p {
			padding-left: 0.5rem;
			display: inline-block;
			color: #333;
			font-size: 1rem;
			line-height: 1rem;
			font-weight: 500;
		}
	}
	button {
		margin-top: 1rem;
	}

	.custom-checkbox {
		--checkbox-color: #6B7AA1;
		display: inline-grid;
  place-content: center;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: var(--checkbox-color);
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid var(--checkbox-color);
  border-radius: 4em;
  transform: translateY(0);
  &::before {
  	border-radius: 4em;
	  content: "";
	  width: 0.65em;
	  height: 0.65em;
	  transform: scale(0);
	  transition: 10ms transform ease-in-out;
	  box-shadow: inset 1em 1em var(--checkbox-color);
  }
  &:checked::before {
  	transform: scale(1);
  }
	}

	@media (max-width: 768px) {
		padding-bottom: 1rem;
		label {
			p {
			font-size: 0.8rem;
			line-height: 0.8rem;				
			}
		}
	}

		@media (max-width: 446px) {
			button {
				margin-top: .5rem;
			}
			button.main-button, button.simple-button {
				width: 100%;
			}
		.answer-box {
			padding-bottom: 2.5rem;
		}
	}
`

export const PrepareTestStyle = styled.div`
	padding: 3rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	color: #6B7AA1;
	text-align: center;

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100px;
		background-color: #f1f3f4;
		width: 12rem;
		height: 12rem;
		text-align: center;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
		transition: all 100ms ease-in;
		&:hover {
		box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);	
		}
	}
	
	svg {
		margin-right: -.5rem;
		font-size: 7rem;
		color: #6B7AA1;
		cursor: pointer;
		transition: color 150ms;
		&:hover {
			color: #6B7AA1;
		}
	}

	@media (max-width: 768px) {
		padding: 1rem 0;

		.play-wrapper {
			span {
				width: 8rem;
				height: 8rem;	
			}
			svg {
				font-size: 4rem;
			}
		}
	}
`