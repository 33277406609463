import {DonateStyles} from '../wrappers/DonatePageStyles'
import Divider from '@mui/material/Divider'
import equalizer from '../img/equalizer.png'
import {useNavigate} from "react-router-dom"


function DonatePage() {
	const navigate = useNavigate()
	
	return(
		<DonateStyles id="donate" className="container">
			<div className="main-block">
				<h2 className="main-header">Pricing</h2>
				<p>Explore Our Subscription Options and Choose the Plan that Fits You Best:</p>
				<div className="subscribes">
					<div className="subscribe-item">
						<h3>Student</h3>
						<Divider/>
						<h2>5<sup>$</sup><sub>month</sub></h2>
						<ul>
							<li>24/7 all tests available</li>
							<li>Regular updates</li>
							<li>A community forum that you'll get access to</li>
						</ul>
						<button onClick={() => navigate('/login')} className="simple-button inverse">Subscribe</button>
					</div>
					<div className="subscribe-item">
						<h3>Advanced</h3>
						<Divider/>
						<h2>19<sup>$</sup><sub>month</sub></h2>
						<ul>
							<li>24/7 all tests available</li>
							<li>Regular updates</li>
							<li>Monthly group workshops with our tutors</li>
						</ul>
						<button onClick={() => navigate('/login')} className="simple-button inverse">Subscribe</button>
					</div>
					<div className="subscribe-item">
						<h3>Pro</h3>
						<Divider/>
						<h2>99<sup>$</sup><sub>month</sub></h2>
						<ul>
							<li>24/7 all tests available</li>
							<li>Regular updates</li>
							<li>Monthly 1-on-1 zoom-meetings with our tutors</li>
						</ul>
						<button onClick={() => navigate('/login')} className="simple-button inverse">Subscribe</button>
					</div>
				</div>
			</div>
			<img src={equalizer} alt="equalizer" className="euqalizer"/>
		</DonateStyles>
		)
}

export default DonatePage