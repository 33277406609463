import Test from './Test'
import {useEffect, useState, useRef} from 'react'
import {searchTestAction} from '../actions/testsInfoAction'
import {useDispatch} from 'react-redux'
import {AppStateType} from '../reducers/index'
import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {useSelector} from 'react-redux'
import LoadingGif from './LoadingGif'
import MainPage from './MainPage';
import AboutProject from './AboutProject'
import {HomeStyle, TestList} from '../wrappers/HomeStyle'
import {resetCurrentTestAction} from '../actions/currentTestAction'
import {finishedTestReset} from '../actions/finishedTestAction'
import SearchBox from './SearchBox'
import DonatePage from './DonatePage'
import IconButton from '@mui/material/IconButton'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleUp, faArrowUpLong} from '@fortawesome/free-solid-svg-icons'
import {useLocation, useNavigate} from "react-router-dom"
import {fetchCurrentUser} from '../actions/userAction'
import {setAlert} from '../actions/infoAlertAction'
import {StayInTouchForm} from './StayInTouch'
import SubscribePage from './Subscribe'

function Home() {
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
	const {pathname} = useLocation()
	const arrowUp = <FontAwesomeIcon icon={faAngleUp}/>
	const {tests, isLoading, page, perPage, totalTests} = useSelector((state: AppStateType) => state.testsInfo)
	const {testId: currentId} = useSelector((state: AppStateType) => state.currentTest)
	const {testId: finishedId} = useSelector((state: AppStateType) => state.finishedTest)
	const {isLogedIn} = useSelector((state: AppStateType) => state.user)
	const [pageLoading, setLoading] = useState<boolean>(true)
	const testsDiv = useRef<HTMLDivElement>(null)
	const {hash} = window.location
	const upIcon = <FontAwesomeIcon icon={faArrowUpLong} />
	const navigate = useNavigate()
	

	const [scrollY, setScrollY] = useState<number>(0)
	const [scrollBtnOn, setScrollBtnOn]= useState<boolean>(false)

	function logit() {
	 setScrollY(window.pageYOffset)
	}

	//onscroll event
	useEffect(() => {
	 function watchScroll() {
	   window.addEventListener("scroll", logit)
	 }
	 watchScroll()
	 return () => {
	   window.removeEventListener("scroll", logit)
	 }
	})

	//scroll button hook
	useEffect(() => {
		if(scrollY > 700) {
			setScrollBtnOn(true)
		} else {
			setScrollBtnOn(false)
		}
	}, [scrollY])

	//test loading hook
	useEffect(() => {
		if (pathname === '/' && totalTests === 0) {
			dispatch(searchTestAction({page}))	
		}
	}, [dispatch, pathname, page, totalTests])


	useEffect(() => {
		if (hash === '#tests' && testsDiv !== null && !isLoading) {
			window.scrollTo({top: testsDiv?.current?.offsetTop, behavior: "smooth"})
		}
	}, [testsDiv.current, hash, isLoading])

	//checking user
	useEffect(() => {
		dispatch(fetchCurrentUser())
	}, [dispatch, isLogedIn])

	useEffect(() => {
		setLoading(false)
	}, [])

	return (
		pageLoading ? <div className="loader-container"><LoadingGif/></div>  : <>
		<MainPage/>
		<HomeStyle className="container" id="tests" ref={testsDiv}>
			<h2 className="main-header">Practice modules</h2>
			<SearchBox total={totalTests}/>
				{isLoading
					? <LoadingGif/>
					: <TestList>		
						{totalTests === 0 && <h4 className="not-found-header">No results were found for your search, please try again!</h4> }	
						{tests.map(test => {
							return <Test 
							name={test.name} 
							complexity={test.complexity}
							slug={test.slug}
							id={test._id}
							description={test.description}
							key={test.slug}
							technique={test.technique}
							quantity={test.quantity}
							updatedAt={test.updatedAt}
							/>
						})}
				</TestList>}
				{!isLogedIn && <div className="button-container center"><button onClick={() => navigate('/login')} className="main-button more-button">Submit for more {upIcon}</button></div>}
				<IconButton 
					className="scroll-to-top"    
					onClick={() => {
			    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
			  }}
			  color="default"
			  style={{
			  	display: scrollBtnOn ? 'block': 'none'
			 	}}>
			  {arrowUp}
			</IconButton>
		</HomeStyle>
		<AboutProject/> 
		<DonatePage/>
		<SubscribePage/>
		<StayInTouchForm/>
		</>
	)
}

export default Home;