import styled from 'styled-components'
import {useNavigate} from "react-router-dom"

const ErrorPAgeStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(100vh - 7rem);

  h3 {
    text-transform: uppercase;
  }

  button {
    margin-top: 1rem;
  }
`

const Error = () => {
  const navigate = useNavigate()
  return (
    <ErrorPAgeStyles>
      <div>
        <h3 className="main-header">Ohh! Page Not Found</h3>
        <p>We can't seem to find the page you're looking for</p>
        <button className="simple-button inverse" onClick={() => navigate('/')}>back home</button>
      </div>
    </ErrorPAgeStyles>
  );
};
export default Error