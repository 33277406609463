import {useEffect, useState} from 'react'
import {searchTestAction} from '../actions/testsInfoAction'
import {useDispatch} from 'react-redux'
import {AppStateType} from '../reducers/index'
import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {useSelector} from 'react-redux'
import LoadingGif from './LoadingGif'
import { deleteHistoryAction, getHistoryAction} from '../actions/progressHistoryAction'
import { IStateTypeHistory, IcompletedTests } from '../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRightLong, faMedal, faCirclePlay} from '@fortawesome/free-solid-svg-icons'
import { getIcon } from '../lib/getIcon'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { UserHistoryStyles, DeleteModal } from '../wrappers/UserHistoryStyles'
import { Link, useNavigate } from 'react-router-dom'
import Modal from '@mui/material/Modal'


const UserHistory = () => {
  const [page, setPage] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
  const {completedTests, numOfPages, isLoading}: IStateTypeHistory = useSelector((state: AppStateType) => state.completedHistory)
  const {id: userId, role} = useSelector((state: AppStateType) => state.user)
  const rightIcon = <FontAwesomeIcon icon={faArrowRightLong} />
  const medalIcon = <FontAwesomeIcon icon={faMedal} />
  const smallPlayIcon = <FontAwesomeIcon icon={faCirclePlay} />
  let index = 0
  const navigate = useNavigate()


  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleSubmit = () => {
    setOpenModal(false)
    dispatch(deleteHistoryAction(role))
  }

  const currentTestHandler = (id: string) => {
    navigate(`/user/my-page/current-test/${id}`)
  }

  useEffect(() => {
    dispatch(getHistoryAction(page))
  }, [dispatch, page])


  return(
    <UserHistoryStyles>
      { isLoading ? <div className="loader-container"><LoadingGif/></div> : <>
      <h3 className="main-header">Completed tests</h3>
      <div className="tests-list">
      {completedTests.length === 0 
      ? <p>You haven't completed any tests yet. It's time to <Link to={`/user/my-page/train`}>start practice!</Link></p> 
      : completedTests.map((test: IcompletedTests) => {
        return <div className="test-item" key={`${test.slug}-${index++}`} onClick={() => navigate(`/user/my-page/current-test/${test.test}`)}>
          <p className="test-technique">{getIcon(test.technique)} {test.technique}</p>
          <h5>{test.name}</h5>
          <p className="test-technique">{medalIcon} Your result: <b>{test.result} / 20</b></p>
          <a>{smallPlayIcon} Practice again</a>
        </div>
      })}
      </div>
      {numOfPages > 0 && <>
        <Pagination count={numOfPages} page={page} onChange={handleChange} />
        <button className="simple-button inverse" onClick={handleOpen}>Delete history</button>
      </>}
      </>}
      {openModal && <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
        <DeleteModal>
          <h3>Are you sure?</h3>
          <span>All history will be deleted</span>
          <div>          
            <button onClick={handleSubmit} className="simple-button inverse">Yes</button>
            <button onClick={handleClose} className="simple-button inverse">No</button>
          </div>
        </DeleteModal>
       </Modal>}
    </UserHistoryStyles>
    )
}

export default UserHistory
