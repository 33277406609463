import {IStateTypeHistory, IcompletedTests} from '../types'

export const initialState: IStateTypeHistory = {
	completedTests: [
	{	name: 'string',
			slug: 'string',
			complexity: 1,
			result: 1,
			technique: 'string',
			test: 'string', 
			user: 'string'}
		],
	numOfPages: 0,
	isLoading: true
}

const progressHistoryReducer = (state = initialState, action: {type: string, payload: {completedTests: IcompletedTests, numOfPages: number}}) => {
	switch(action.type) {
		case 'LOADING_PROGRESS_HISTORY' : {
			return {
				...state,
				isLoading: true
			}
		}
		case 'FETCH_PROGRESS_HISTORY' : {
			return {
				...state,
				completedTests: action.payload.completedTests,
				numOfPages: action.payload.numOfPages,
				isLoading: false
			}
		}
		default : 
			return {
				...state,
			}
	}
}

export default progressHistoryReducer