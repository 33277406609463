import {FormPage} from '../wrappers/FormPageWrapper'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import {useState, useEffect} from 'react'
//@ts-ignore
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {throwErrorAction} from '../actions/errorAction'
import {useNavigate} from "react-router-dom"
import Button from '@mui/material/Button'
import { validateEmail } from '../lib/validateData'
import Alert, {AlertColor} from '@mui/material/Alert'
import { setAlert } from '../actions/infoAlertAction'
import {customAuthFetch} from '../axios'
import {SvgComponent} from './CourseChart'

interface ISubscribeValues {
	email: string
}

export function StayInTouchForm() {
	const initialValues = {
		email: ''
	}

	const [values, setValues] = useState<ISubscribeValues>(initialValues)
	const [inputError, setInputError] = useState<boolean>(false)
	const [alert, setAlertValue] = useState<string>('')
	const [alertType, setAlertType] = useState<any>('success')
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()

 const handleChange = (e: any) => {
  setValues({ ...values, [e.target.name]: e.target.value })
	}

 const validateData = (values: ISubscribeValues) => {
		if(!validateEmail(values.email)) {
		setInputError(true)
		setAlertType('error')
		setAlertValue('Error! Enter a valid email adress')
		return false
		} 
		return true
	}

  const formSubmit = async (e: any) => {
  	e.preventDefault()
  	nprogress.start()
  	setAlertValue('')
  	try {
			if (validateData(values)) {
		  	setInputError(false)
		  	setValues(initialValues)
		  	const {data} = await customAuthFetch.post('/subscribe', {
		  		email: values.email
		  	})
		  	setAlertType('success')
		  	setAlertValue('Success!')
		  	dispatch(setAlert('Success!'))
			}
  	} catch(error: any) {
				const dataMsg: string = error.response?.data?.msg
			if (dataMsg) {
				dispatch(throwErrorAction(dataMsg))
			} else {
				const message = `Error message: ${error.message}`
				dispatch(throwErrorAction(message))  			
			}
  		setAlertType('error')
  		setAlertValue('Error!')
  	}
  	nprogress.done()
  }

	return(
		<>
	 	<FormPage className="stay-touch">
	 	 <h2 className="main-header">Stay In Touch</h2>
	 	 <p className="sub-header">Subscribe And Receive Regular Updates About App Features <br/> And Aural Skills Developing Methods:</p>
		  <Box
		  	className="loginForm stay-touch-box"
		    component="form"
		    sx={{
		      '& .MuiTextField-root': { m: 0, width: '100%' },
		    }}
		    noValidate
		    autoComplete="off"
		    onSubmit={formSubmit}
		  >
			  <div>
			  {alert.length > 0 && <Alert severity={alertType} className="alert-message">{alert}</Alert>}
			  				<h3></h3>
	        <TextField
	        	autoComplete="email"
	        	error={inputError ? true : false}
	        	color="secondary"
	          required
	          fullWidth
	          name="email"
	          label="Email"
	          type="email"
	          size="small"
	          helperText={inputError ? "Enter a valid email adress" : ""}
	          value={values.email}
	          onChange={handleChange}
	          InputLabelProps={{
	            shrink: true,
	          }}
	        />
			  </div>
        <button 
        type='submit'
        className="simple-button inverse stay-in-button">
        	Subscribe
        </button>
		  </Box>
	 	</FormPage>	
	 	</>
		)
}