import styled from 'styled-components'

export const FinishedTestStyles = styled.div`
	box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
	h3.main-header {
		text-align: center;
    &:after {
    opacity: .6;
    margin-top: .5rem;
    content: '';
    display: block;
    width: 4rem;
    height: 2px;
    background-color: #EF4F4F;
    margin-left: auto;
    margin-right: auto;
  }
	}
	.main-info {
		h4 {
			text-align: center;
		}
		&-success {
			text-align: center;
			font-size: 10rem;
			opacity: 0.8;
		}
	}
	p {
		padding: .1rem 0rem;
		line-height: 100%;
	}
`
export const AnswersBox = styled.div`
	padding: .5rem 0 4rem 0;
	.button-box {
		padding-top: 1rem;
		display: flex;
		justify-content: flex-start;
		width: 100%;
		.main-button {
			margin-right: 1rem;
		}
	}

	@media screen and (max-width: 768px) {
		.button-box {
			flex-direction: column;
			button {
				width: 100%;
			}
			.main-button {
				margin-right: 0;
				margin-bottom: .5rem;
			}
		}
	}
	
`
export const AnswerItem = styled.div`
	margin-bottom: 1rem;
	.incorrect {
		color: red;
		font-weight: 700;
		text-decoration: line-through;
	}
	.correct {
		color: green;
		font-weight: 700;
		text-decoration: underline dotted;	
	}
	.item-body {
		background-color: #f1f3f4;
		padding: 2rem 2rem 0 2rem;
		border: 2px solid #f1f3f4;
		border-radius: 5px;	
	}
	h4 {
		padding-bottom: 1rem;
		font-size: 1.1rem;
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 1rem;
		.item-body {
			padding: 0 1rem .5rem 1rem;
		}
		h4 {
			font-size: 1rem;
		}
	}
`