import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {AppStateType} from '../reducers/index'
import customFetch from '../axios'
import axios from 'axios'
import {throwErrorAction} from './errorAction'

export const getHistoryAction = (page: number) => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	const url = `https://earmentor.onrender.com/api/v1/tests/history/?&page=${page}`

	try {
		dispatch({type: 'LOADING_PROGRESS_HISTORY'})
		const {data} = await axios.get(url, {withCredentials: true})
		dispatch({type: 'FETCH_PROGRESS_HISTORY', payload: {completedTests: data.tests, numOfPages: data.numOfPages}})
	} 
	catch(e: any) {
		console.log(e.message)
		const message = `Error message: ${e.message}`
		dispatch(throwErrorAction(message))
	}
}

export const deleteHistoryAction = (role: string) => async (dispatch: ThunkDispatch<AppStateType, void, Action>) => {
	const url = `https://earmentor.onrender.com/api/v1/tests/delete-history`

	if(role === 'testUser') {
		const message = 'You are in testing mode. You cannot delete items.'
		return dispatch(throwErrorAction(message))	
	}

	try {
		dispatch({type: 'LOADING_PROGRESS_HISTORY'})
		const {data} = await axios.delete(url, {
			headers: { 'Content-Type': 'application/json' },
	  withCredentials: true
		})
		//data.result for getting deletedCount
		dispatch(getHistoryAction(1))
	} catch(e: any) {
				console.log(e.message)
				const message = `Error message: ${e.message}`
				dispatch(throwErrorAction(message))		
	}
}