import {FormPage} from '../wrappers/FormPageWrapper'
import Alert from '@mui/material/Alert'
import {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import {Link} from "react-router-dom"
//@ts-ignore
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {throwErrorAction} from '../actions/errorAction'
import { customAuthFetch } from '../axios'
import { validatePassword } from '../lib/validateData'
import {useNavigate} from "react-router-dom"
import {useErrorHandler} from '../authApi'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface IResetValues {
	password: string,
}

export default function ResetPassword() {

	const initialValues = {
		password: '',		
	}
	const [values, setValues] = useState<IResetValues>(initialValues)
	const [passwordError, setPasswordError] = useState<boolean>(false)
	const [alert, setAlert] = useState<string>('')
	const [alertType, setAlertType] = useState<any>('success')
	const query = useQuery()
	const [loading, setLoading] = useState<boolean>(false)
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
	const navigate = useNavigate()

 const handleChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const validateData = (values: IResetValues) => {
  	if(!validatePassword(values.password)) {
			setPasswordError(true)
			return false
  	} 
  	return true
  }

	const formSubmit = async (e: any) => {
		e.preventDefault()
   	setLoading(true)
  	nprogress.start()
  	setAlert('')
  	try {
	  	if (validateData(values)) {
	  		const {data} = await customAuthFetch.post('/reset-password', {
	  			password: values.password,
	  			passwordToken: query.get('passwordToken'),
	  			email: query.get('email')
	  		})
		  	setPasswordError(false)
		  	setValues(initialValues)
		  	setAlert('Done! Now you can login...') 	
		  	setTimeout(() => {
		  		navigate('/login')
		  	}, 2000)	
	  	}

  	} catch(error: any) {
			const dataMsg: string = error.response?.data?.msg
			if (dataMsg) {
				dispatch(throwErrorAction(dataMsg))
			} else {
			const message = `Error message: ${error.message}`
			dispatch(throwErrorAction(message))  			
			}
  		setAlertType('error')
  		setAlert('Error!')	
  	}
  	setLoading(false)
  	nprogress.done()
	}

	return(
		<FormPage>
			<Box
		  	className="loginForm"
		    component="form"
		    sx={{
		      '& .MuiTextField-root': { m: 0, width: '100%' },
		    }}
		    noValidate
		    onSubmit={formSubmit}
		  >
			  <div>
			  	{alert.length > 0 && <Alert severity={alertType} className="alert-message">{alert}</Alert>}
			  	<h3>Enter new password</h3>
	        <TextField
	        	autoComplete="password"
	        	error={passwordError ? true : false}
	        	color="secondary"
	        	required
	        	fullWidth
	        	name="password"
	          label="Password"
	          type="password"
	          size="small"
	          helperText={passwordError ? "Password must contain 6 characters or more" : ""}
	          value={values.password}
	          onChange={handleChange}
	          InputLabelProps={{
	            shrink: true,
	          }}
	        />
			  </div>
        <button 
        type='submit'
        className="simple-button inverse">
        	Submit
        </button>
		  </Box>
		</FormPage>
		)
}