import {useEffect, useState} from 'react'
import {searchTestAction} from '../actions/testsInfoAction'
import {AppStateType} from '../reducers/index'
import {useSelector, useDispatch} from 'react-redux'
import LoadingGif from './LoadingGif'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRightLong, faChartSimple, faCirclePlay} from '@fortawesome/free-solid-svg-icons'
import { getIcon } from '../lib/getIcon'
import { UserTrainingStyles } from '../wrappers/UserTrainingStyles'
import { currentTestAction } from '../actions/currentTestAction'
import { ItestInfoState, testInfoType } from '../types'
import {useNavigate} from "react-router-dom"
import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'


const UserTrainingPage = () => {
  const {tests, isLoading, page, perPage, totalTests}: ItestInfoState = useSelector((state: AppStateType) => state.testsInfo)
  const rightIcon = <FontAwesomeIcon icon={faArrowRightLong} />
  const chartIcon = <FontAwesomeIcon icon={faChartSimple} />
  const smallPlayIcon = <FontAwesomeIcon icon={faCirclePlay} />
  const {name, id: userId} = useSelector((state: AppStateType) => state.user)
  const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
  const navigate = useNavigate()

  const currentTestHandler = (id: string) => {
    navigate(`/user/my-page/current-test/${id}`)
  }

  useEffect(() => {
    dispatch(searchTestAction({}))  
  }, [dispatch])

  return(
    <UserTrainingStyles>
      <h3 className="main-header">Modules</h3>
      <div className="tests-container">
        {isLoading ? <div className="loader-container"><LoadingGif/></div> 
          : <div className="tests-list">
            {totalTests === 0 && <h4 className="not-found-header">По вашему запросу ничего не найдено, попробуйте снова!</h4> }
            {tests.map((test: testInfoType) => {
              return <div className="test-item" key={test._id} onClick={() => currentTestHandler(test._id)}>
                <p className="test-technique">{getIcon(test.technique)} {test.technique}</p>
                <h5>{test.name}</h5>
                <p className="test-technique">{chartIcon} Level: {test.complexity} / 10</p>
                <a>{smallPlayIcon} Practice</a>
              </div>
            })}
          </div> }
        
      </div>
        
    </UserTrainingStyles>
    )
}

export default UserTrainingPage
