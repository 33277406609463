import {combineReducers} from 'redux'
import testsInfoReducer from './testsInfoReducer'
import currentTestReducer from './currentTestRecuder'
import finishedTestReducer from './finishedTestReducer'
import errorReducer from './errorReducer'
import userReducer from './userReducer'
import infoAlertReducer from './infoAlertReducer'
import progressStatsReducer from './progressStatsReducer'
import progressHistoryReducer from './progressHistoryReducer'



const rootReducer = combineReducers({
	testsInfo: testsInfoReducer,
	currentTest: currentTestReducer,
	finishedTest: finishedTestReducer,
	error: errorReducer,
	user: userReducer, 
	alert: infoAlertReducer, 
	progress: progressStatsReducer, 
	completedHistory: progressHistoryReducer
})

type rootReducerType = typeof rootReducer
export type AppStateType = ReturnType<rootReducerType>


export default rootReducer