import {Istats} from '../types'

export const initialState = {
	stats: {
		'harmonic': 1,
		'tech': 1,
		'rhythm': 1
	},
	isLoading: true
}

const progressStatsReducer = (state = initialState, action: {type: string, payload: {stats: Istats}}) => {
	switch(action.type) {
		case 'LOADING_PROGRESS_DATA' : {
			return {
				...state,
				isLoading: true
			}
		}
		case 'FETCH_PROGRESS_DATA' : {
			return {
				...state,
				stats: action.payload.stats,
				isLoading: false
			}
		}
		default : 
			return {
				...state,
			}
	}
}

export default progressStatsReducer