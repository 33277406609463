import { VictoryBar, VictoryChart, VictoryAxis,
        VictoryTheme, VictoryPie, VictoryLabel } from 'victory'
import {useState, useEffect} from 'react'
import {DashboardStyles} from '../wrappers/DashboardWrapper'
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {throwErrorAction} from '../actions/errorAction'
import LoadingGif from './LoadingGif'
import { searchProgressDataAction } from '../actions/progressStatsAction'
import Divider from '@mui/material/Divider'


const Dashboard = () => {
  const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
  const {isLoading, stats} = useSelector((state: AppStateType) => state.progress)
  const [metric, setMetric] = useState(10)
  const [nextLevel, setNextLevel] = useState(1)

  const data = [
    {"tech": 1, "level": stats.rhythm},
    {"tech": 2, "level": stats.harmonic},
    {"tech": 3, "level": stats.tech}
  ]

  function getUserLevel() {
    const sum = stats.rhythm + stats.harmonic + stats.tech
    return Math.floor(sum / 3)
  }

  function getNextLevel() {
    const sum = stats.rhythm + stats.harmonic + stats.tech
    if ((sum / 3) % 1 === 0 && sum < 30) {
      setNextLevel((sum / 3) + 1)
      let lvl = (sum / 3) % 1
      lvl = Math.floor(lvl * 100)
      setMetric(lvl + 2)
    }
    else if (sum >= 30) {
      setNextLevel(10)
      setMetric(100)
    } 
    else if ((sum / 3) % 1 !== 0) {
      setNextLevel(Math.ceil(sum / 3))
      let lvl = (sum / 3) % 1
      lvl = Math.floor(lvl * 100)
      setMetric(lvl)
    } 
  }

  useEffect(() => {
    getNextLevel()
  }, [stats])

  useEffect(() => {
    dispatch(searchProgressDataAction())
  }, [dispatch])

  return(
    <DashboardStyles>
      { isLoading ? <div className="loader-container"><LoadingGif/></div> : <>
      <div className="chart">
      <h3 className="main-header">Your skills</h3>
      <p>Your current level based on solved tests <b>&#8212; {getUserLevel()} level</b></p> 
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={20}
          maxDomain={{ y: 10 }}
          height={300}
          padding={{ top: 20, bottom: 50, left: 60, right: 50 }}
        >
          <VictoryAxis
            tickValues={[1, 2, 3]}
            tickFormat={["Timing", "Harmonic", "Technique"]}
            axisLabelComponent={<VictoryLabel className="axis-label" dy={24} />}
            label={"Type of training"}

          />
          <VictoryAxis
            dependentAxis
            domain={[0, 10]}
            axisLabelComponent={<VictoryLabel className="axis-label" dy={-20} />}
            label={"Level"}
          />
          <VictoryBar
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 }
            }}
            barRatio={.8}
            data={data}
            style={{ data: { fill: "#eda29e" } }}
            x="tech"
            y="level"
          />
        </VictoryChart>     
      </div>

      <div className="progress-bar">
        <p>{getUserLevel() === 10 ? 'Круто, поздравляем!': 'Next level:'}</p>
        <svg width={300} height={300}>
        <text x={150} y={150} textAnchor="middle" >
          {`${nextLevel} lvl`}
        </text>
        <VictoryPie 
            padAngle={0}
            standalone={false}
            labelComponent={<span/>}
            innerRadius={70}
            width={300} height={300}
            data={[{'key': '', 'y': metric}, {'key': '', 'y': (100-metric)} ]}
            colorScale={["#BB95B2", "#EEEEEE" ]}
        />
        </svg>
      </div>
      </>}
    </DashboardStyles>
    )
}

export default Dashboard
