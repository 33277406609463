import {useDispatch} from 'react-redux'
import {AppStateType} from '../reducers/index'
import {ThunkDispatch} from 'redux-thunk'
import {Action} from 'redux'
import {useSelector} from 'react-redux'
import Modal from '@mui/material/Modal'
import {useState, useEffect} from 'react'
import styled from 'styled-components'
import {clearErrorAction} from '../actions/errorAction'
import {useNavigate} from "react-router-dom"


const ErrorModal = styled.div`
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 background-color: #fff;
 border: 2px solid #000;
 text-align: center;
 padding: 2rem 4rem;

 p, span {
 	margin-bottom: 2rem;
  display: block;
 }

 span {
 	color: #EF4F4F;
 }

 @media (max-width: 768px) {
  padding: 1rem 1rem 2rem 1rem;
  width: 90%;
  button {
    width: 100%;
  }
 }
`

function ErrorPage() {
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
	const {errorMessage} = useSelector((state: AppStateType) => state.error)
	const navigate = useNavigate()

 const [open, setOpen] = useState(false)
 const handleOpen = () => setOpen(true)
 const handleClose = () => {
 	dispatch(clearErrorAction())
 }

 useEffect(() => {
 	if (errorMessage) {
 		setOpen(true)
 	} else {
 		setOpen(false)
 	}
 }, [errorMessage])


	return(
		<div>
    {errorMessage 
    	? <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
        <ErrorModal>
        	<h3>Error!</h3>
        	<span>{errorMessage}</span>
        	<button onClick={handleClose} className="simple-button">Back</button>
        </ErrorModal>
       </Modal> 
     : null}
		</div>
		)
}


export default ErrorPage