import {useState, useEffect} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';	
import {faChartSimple, faGuitar, faClockRotateLeft, 
faUser, faBars, faChevronLeft, faChevronRight, faRightFromBracket, faCirclePlay} from '@fortawesome/free-solid-svg-icons'
import { Routes, Route, Link, Outlet, NavLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { customAuthFetch } from '../axios';
import {useNavigate, useLocation} from "react-router-dom";
import {AppStateType} from '../reducers/index'
import {Action} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {throwErrorAction} from '../actions/errorAction'
//@ts-ignore
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {logoutAction} from '../actions/userAction'
import {MyPageStyles} from '../wrappers/UserMyPageWrapper'
import {setAlert} from '../actions/infoAlertAction'


function UserMyPage({children}: any) {
	const [open, setOpen] = useState(false)
	const [testProcessing, setTestProcessing] = useState(false)
	const iconStats = <FontAwesomeIcon icon={faChartSimple} />
	const trainIcon = <FontAwesomeIcon icon={faGuitar} />
	const historyIcon = <FontAwesomeIcon icon={faClockRotateLeft} />
	const userIcon = <FontAwesomeIcon icon={faUser} />
	const menuIcon = <FontAwesomeIcon icon={faBars} />
	const chevronLeft = <FontAwesomeIcon icon={faChevronLeft} />
	const chevronRight = <FontAwesomeIcon icon={faChevronRight} />
	const logoutIcon = <FontAwesomeIcon icon={faRightFromBracket} />
	const playTrainIcon = <FontAwesomeIcon icon={faCirclePlay} />

	const navigate = useNavigate()
	const location = useLocation()
	const dispatch: ThunkDispatch<AppStateType, void, Action> = useDispatch()
	const {name, id} = useSelector((state: AppStateType) => state.user)

	useEffect(() => {
		dispatch(setAlert(`Hi, ${name}!`))
	}, [name, dispatch])

	useEffect(() => {
		if (location.pathname.match('current-test') || location.pathname.match('finished-test')) {
			setTestProcessing(true)
		} else {
			setTestProcessing(false)
		}
	}, [location])

	const logoutHandler = async () => {
		nprogress.start()
		try {
			const {data} = await customAuthFetch.patch('/logout')
			dispatch(logoutAction())
			dispatch(setAlert(`See you again, ${name}!`))
			navigate('/')
		} catch(error: any) {
					const dataMsg: string = error.response?.data?.msg
					if (dataMsg) {
						dispatch(throwErrorAction(dataMsg))
					} else {
					const message = `Error message: ${error.message}`
					dispatch(throwErrorAction(message))  			
					}
	  	}
	  nprogress.done()
	}

	const sideBarHandler = () => {
		if(testProcessing) {
			setTestProcessing(false)
			return false
		} else if(open) {
			return false
		} else if(!open) {
			return true
		} else {
			return true
		}
	}

	return (
		<MyPageStyles className="user-page">
			<div className={`sidebar ${testProcessing ? "active" : open ? "active" : ""}`}>
				<ul className="sidebar_items">
	    <li className="sidebar_items_list nav-item" onClick={() => setOpen(sideBarHandler)}>
	    	<a className="sidebar_nav_item">
	      <i className='bx'>{open ? chevronRight : chevronLeft}</i>
	    	</a>
	    </li>	
			<NavLink to="dashboard"   className={({ isActive }) =>
    isActive ? "active" : ""}>     
				<li className="sidebar_items_list">
		      <div className="sidebar_nav_item"> 
		        <i className='bx' >{iconStats}</i>
		        <span>Skills</span>
		      </div>
		    </li>
     </NavLink>
     <NavLink to="train" className={({ isActive }) =>
    isActive ? "active" : ""}>
			 <li className="sidebar_items_list">
			  <div className="sidebar_nav_item"> 
			    <i className='bx' >{playTrainIcon}</i>
			    <span>Modules</span>
			  </div>
			 </li>	
     </NavLink>
    	<NavLink to="history" className={({ isActive }) =>
    isActive ? "active" : ""}>
	     <li className="sidebar_items_list">
	      <div className="sidebar_nav_item"> 
	        <i className='bx' >{historyIcon}</i>
	        <span>History</span>
	      </div>
	     </li>	
     </NavLink>		
     <NavLink to="user-info" className={({ isActive }) =>
    isActive ? "active" : ""}>
	     <li className="sidebar_items_list">
	      <div className="sidebar_nav_item"> 
	        <i className='bx' >{userIcon}</i>
	        <span>Stats</span>
	      </div>
	     </li>
     </NavLink>	
     <Divider/>
     <div onClick={logoutHandler}>
	     <li className="sidebar_items_list">
	      <div className="sidebar_nav_item"> 
	        <i className='bx' >{logoutIcon}</i>
	        <span>Log Out</span>
	      </div>
	     </li>
     </div>	
				</ul>
			</div>
			<div className="content-panel">
				<Outlet/>
			</div>
		</MyPageStyles>
	)
}

export default UserMyPage